import { useEffect } from 'react';
import eventEmitter from '@/lib/eventEmitter';

function useCustomEvent(eventName: string, callback: CallableFunction): void | null {
  useEffect(() => {
    eventEmitter.on(eventName, callback);
    return () => eventEmitter.off(eventName, callback);
  }, [eventName, callback]);

  return null;
}

export default useCustomEvent;
