import { Permission } from '@/types/auth';

export const systemPermissions: Record<string, Permission> = {
  newTransactionsView: 'MP.TRANSACTIONS.VIEW',
  allTransactionsView: 'MP.TRANSACTIONS.ALL.VIEW',
  allTransactionsDownload: 'MP.TRANSACTIONS.ALL.DOWNLOAD',
  totalsView: 'MP.TRANSACTIONS.TOTALS.VIEW',
  merchantAccountView: 'MP.PROFILE.VIEW',
  usersView: 'MP.USERS.VIEW',
  usersModify: 'MP.USERS.MODIFY',
  settingsModify: 'MP.SETTINGS.MODIFY',
  qrCodeDownload: 'MP.QR.DOWNLOAD',
  supportView: 'MP.SUPPORT.VIEW',
};
