import { Api } from '@/types/api';

const API: Api = {
  LOGIN_USER: {
    url: '/merchants/authentications/tokens',
    method: 'POST',
  },
  LOGOUT_USER: {
    url: '/users/logout',
    method: 'GET',
  },
  RESET_PASSWORD: {
    url: '/users/password/request',
    method: 'POST',
  },
  CHECK_TOKEN_FROM_EMAIL: {
    url: '/users/password/code',
    method: 'POST',
  },
  SAVE_NEW_PASSWORD: {
    url: '/users/password',
    method: 'POST',
  },
  GET_CURRENT_USER: {
    url: '/me',
    method: 'GET',
  },
  GET_TRANSACTIONS: {
    url: 'transactions/search',
    method: 'POST',
  },
  GET_TOTALS: {
    url: 'transactions/totals',
    method: 'POST',
  },
  GET_MERCHANT_ACCOUNT_INFO: {
    url: 'merchant/merchant-profile',
    method: 'GET',
  },
  UPDATE_MERCHANT_INFO: {
    url: 'merchant/merchant-profile',
    method: 'PUT',
  },
  CONFIRM_MERCHANT_ACCOUNT_INFO: {
    url: 'merchant/merchant-profile/review-confirm',
    method: 'PATCH',
  },
  GET_DOCUMENT_MAPPING: (countryCode) => ({
    url: `merchant/merchant-profile/document-mapping/${countryCode}`,
    method: 'GET',
  }),
  UPLOAD_DOCUMENT: {
    url: 'merchant/merchant-profile/document',
    method: 'POST',
  },
  DELETE_ALL_DOCUMENT: (workFlowId) => ({
    url: `merchant/merchant-profile/workflow/${workFlowId}/actions/delete`,
    method: 'DELETE',
  }),
  DELETE_DOCUMENTS: (documentIds) => ({
    url: `merchant/merchant-profile/document/actions/delete-disassociate?documentIds=${documentIds}`,
    method: 'DELETE',
  }),
  SUBMIT_DOCUMENTS: {
    url: 'merchant/merchant-profile/generic-document/actions/submit',
    method: 'POST',
  },
  GET_USERS: {
    url: 'user-management/users/list',
    method: 'GET',
  },
  GET_USER: (userId) => ({
    url: `user-management/users/${userId}`,
    method: 'GET',
  }),
  ADD_USER: {
    url: 'user-management/users',
    method: 'POST',
  },
  UPDATE_USER: (userId) => ({
    url: `user-management/users/${userId}`,
    method: 'PUT',
  }),
  RESEND_WELCOME_EMAIL: (userId) => ({
    url: `user-management/users/${userId}/actions/sendWelcomeEmail`,
    method: 'POST',
  }),
  TOGGLE_USER_ACTIVATION: (userId) => ({
    url: `user-management/users/${userId}`,
    method: 'PATCH',
  }),
  EXPORT_TRANSACTIONS: {
    url: 'transactions/export',
    method: 'GET',
    responseType: 'blob',
  },
  GET_PAYMENT_PROFILES: {
    url: '/merchant/payment-profiles/search',
    method: 'POST',
  },
  DOWNLOAD_QR: {
    url: '/qrcode',
    method: 'POST',
  },
  LOAD_IMAGE: (url) => ({
    url,
    method: 'GET',
  }),
};

export default API;
