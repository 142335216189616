import React from 'react';

import TransactionProvider from '@/components/Transactions/TransactionProvider';
import TodaysTransactions from '@/components/TodaysTransactions';
import PaymentProfileSelection from '@/components/PaymentProfiles/PaymentProfileSelection';
import StickerBanner from '@/components/Banners/StickerBanner';
import PreDefinedStickerBanner from '@/components/Banners/PreDefinedStickerBanner/PreDefinedStickerBanner';

function TodaysTransactionsPage() {
  return (
    <>
      <PaymentProfileSelection />
      <PreDefinedStickerBanner />
      <TransactionProvider>
        <StickerBanner />
        <TodaysTransactions />
      </TransactionProvider>
    </>
  );
}

export default TodaysTransactionsPage;
