import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import BROWSER_STORAGE_KEYS from '@/constants/browserStorageKeys';
import { browserStorageGet, browserStorageSet } from '@/utils/browserStorage';
import useTC from '@/hooks/useTC';
import usePaymentProfiles from '@/hooks/usePaymentProfiles';

const useStyles = makeStyles((theme) => ({
  tooltipBox: {
    width: 345,
    padding: theme.spacing(2),
    position: 'absolute',
    zIndex: 2,
    top: '50%',
    left: '90%',
    transform: 'translate(0, -50%)',
    background: theme.palette.background.header,
    color: theme.palette.common.white,
    boxShadow: theme.shadows[3],
    '&:after': {
      height: 0,
      width: 0,
      content: "''",
      position: 'absolute',
      top: '50%',
      right: '100%',
      transform: 'translate(0, -50%)',
      border: 'solid transparent',
      borderWidth: '12px',
      borderRightColor: theme.palette.background.header,
      pointerEvents: 'none',
    },
  },
  tooltipButton: {
    color: theme.palette.common.white,
  },
}));

function AnnouncementTooltip({ showAnnouncementTooltip, announcementKey, children }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { showTCModal } = useTC() || {};
  const { selectedPaymentProfile, selectedPaymentProfileTodayTransactions } = usePaymentProfiles();
  const [announcement, setAnnouncement] = useState(
    browserStorageGet(BROWSER_STORAGE_KEYS.ANNOUNCEMENT) || {},
  );

  const preventNavigation = (event) => {
    event.preventDefault();
  };

  const handleClick = () => {
    browserStorageSet(BROWSER_STORAGE_KEYS.ANNOUNCEMENT, {
      ...announcement,
      [announcementKey]: true,
    });
    setAnnouncement({
      ...announcement,
      [announcementKey]: true,
    });
  };

  const areOtherModalsAlreadyDisplayed =
    !showTCModal && (!!selectedPaymentProfile || !!selectedPaymentProfileTodayTransactions);
  const showBox =
    showAnnouncementTooltip &&
    !announcement[announcementKey] &&
    !isMobile &&
    areOtherModalsAlreadyDisplayed;

  return showBox ? (
    <Box className={classes.tooltipBox} onClick={preventNavigation}>
      {children}
      <Box
        sx={{
          mt: 2,
          textAlign: 'end',
        }}
      >
        <Button
          variant="text"
          className={classes.tooltipButton}
          type="button"
          onClick={handleClick}
        >
          {t('button.announcement')}
        </Button>
      </Box>
    </Box>
  ) : null;
}

AnnouncementTooltip.defaultProps = {
  showAnnouncementTooltip: false,
  announcementKey: undefined,
  children: null,
};

AnnouncementTooltip.propTypes = {
  showAnnouncementTooltip: PropTypes.bool,
  announcementKey: PropTypes.string,
  children: PropTypes.node,
};

export default AnnouncementTooltip;
