import { ReactElement, useState, useEffect, useCallback, useMemo } from 'react';

import paymentProfilesContext from '@/contexts/paymentProfilesContext';
import useAuth from '@/hooks/useAuth';
import Loader from '@/components/Loader';
import { browserStorageSet, browserStorageGet, browserStorageRemove } from '@/utils/browserStorage';
import BROWSER_STORAGE_KEYS from '@/constants/browserStorageKeys';
import request from '@/lib/request';
import isEmpty from '@/lib/isEmpty';
import API from '@/constants/api';
import { IPaymentProfile, IPaymentProfileResponse } from '@/types/paymentProfile';

type Props = {
  children?: ReactElement;
} & typeof defaultProps;

const defaultProps = {
  children: <div />,
};

type StorageKey =
  | typeof BROWSER_STORAGE_KEYS.PAYMENT_PROFILE_TODAYS_TRANSACTIONS
  | typeof BROWSER_STORAGE_KEYS.PAYMENT_PROFILE;

function PaymentProfileProvider({ children }: Props): ReactElement {
  const [paymentProfiles, setPaymentProfiles] = useState<IPaymentProfile[] | null>(null);
  const [isLoading, setLoading] = useState(false);
  const { user } = useAuth();
  const [selectedPaymentProfile, setSelectedPaymentProfile] = useState<IPaymentProfile | null>(
    null,
  );
  const [selectedPaymentProfileTodayTransactions, setSelectedPaymentProfileTodayTransactions] =
    useState<IPaymentProfile | null>(null);

  const merchantKey = useMemo(
    () => (user?.name || 'merchant').toLowerCase().replace(/\s/g, '_'),
    [user],
  );

  const getStoredPaymentProfile = useCallback(
    (storageKey: StorageKey) => {
      const data = browserStorageGet(storageKey) as { [key: string]: IPaymentProfile };
      return (data && data[merchantKey]) || null;
    },
    [merchantKey],
  );

  const storePaymentProfile = useCallback(
    (storageKey: StorageKey, pp: IPaymentProfile) => {
      browserStorageSet(storageKey, { [merchantKey]: pp });
    },
    [merchantKey],
  );

  const preparePaymentProfile = useCallback(
    ({
      paymentProfileId,
      shopName,
      productDescription,
      productName,
      address,
      amountOption,
    }: IPaymentProfile) => {
      return {
        paymentProfileId,
        shopName: shopName || user?.name || '',
        productDescription,
        productName,
        address,
        amountOption,
      };
    },
    [user],
  );

  const handleChangePaymentProfiles = useCallback(
    (value: IPaymentProfile | null) => {
      if (!value) {
        setSelectedPaymentProfile(null);
        browserStorageRemove(BROWSER_STORAGE_KEYS.PAYMENT_PROFILE);
      } else if (value && value.paymentProfileId === 'all') {
        storePaymentProfile(BROWSER_STORAGE_KEYS.PAYMENT_PROFILE, value);
        setSelectedPaymentProfile(value);
      } else {
        storePaymentProfile(BROWSER_STORAGE_KEYS.PAYMENT_PROFILE_TODAYS_TRANSACTIONS, value);
        setSelectedPaymentProfileTodayTransactions(value);
        storePaymentProfile(BROWSER_STORAGE_KEYS.PAYMENT_PROFILE, value);
        setSelectedPaymentProfile(value);
      }
    },
    [storePaymentProfile],
  );

  const handlePaymentProfilesResponse = useCallback(
    (pp: IPaymentProfile[]) => {
      if (!pp || !Array.isArray(pp)) {
        setPaymentProfiles([]);
      }

      const preparedPaymentProfiles = pp.map(preparePaymentProfile);
      if (preparedPaymentProfiles.length === 1) {
        handleChangePaymentProfiles(preparedPaymentProfiles[0]);
      }

      setPaymentProfiles(preparedPaymentProfiles);
    },
    [preparePaymentProfile, handleChangePaymentProfiles],
  );

  useEffect(() => {
    setSelectedPaymentProfileTodayTransactions(
      getStoredPaymentProfile(BROWSER_STORAGE_KEYS.PAYMENT_PROFILE_TODAYS_TRANSACTIONS),
    );
    setSelectedPaymentProfile(getStoredPaymentProfile(BROWSER_STORAGE_KEYS.PAYMENT_PROFILE));
  }, [getStoredPaymentProfile]);

  useEffect(() => {
    if (!isEmpty(user)) {
      setLoading(true);
      request({
        ...API.GET_PAYMENT_PROFILES,
        data: {
          size: 300,
        },
      })
        .then(({ data }) => {
          handlePaymentProfilesResponse((data as IPaymentProfileResponse)?.content || []);
        })
        .catch((error) => {
          handlePaymentProfilesResponse([]);
          return Promise.reject(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [user, handlePaymentProfilesResponse, getStoredPaymentProfile]);

  const contextValue = useMemo(
    () => ({
      paymentProfiles,
      selectedPaymentProfile,
      selectedPaymentProfileId:
        !selectedPaymentProfile || ['all', null].includes(selectedPaymentProfile.paymentProfileId)
          ? null
          : selectedPaymentProfile.paymentProfileId,
      selectedPaymentProfileTodayTransactions,
      selectedPaymentProfileTodayTransactionsId:
        !selectedPaymentProfileTodayTransactions ||
        ['all', null].includes(selectedPaymentProfileTodayTransactions.paymentProfileId)
          ? null
          : selectedPaymentProfileTodayTransactions.paymentProfileId,
      handleChangePaymentProfiles,
      isLoading,
    }),
    [
      handleChangePaymentProfiles,
      isLoading,
      paymentProfiles,
      selectedPaymentProfile,
      selectedPaymentProfileTodayTransactions,
    ],
  );

  return (
    <paymentProfilesContext.Provider value={contextValue}>
      <Loader isLoading={isLoading}>
        {!isEmpty(user) && paymentProfiles ? children : undefined}
      </Loader>
    </paymentProfilesContext.Provider>
  );
}

PaymentProfileProvider.defaultProps = defaultProps;

export default PaymentProfileProvider;
