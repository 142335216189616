import {
  GET_MAX_TRANSACTIONS_FROM_API,
  GET_MAX_TRANSACTIONS_FROM_API_M_SCREEN,
  GET_MAX_TRANSACTIONS_FROM_API_L_SCREEN,
} from '@/constants/common';
import { ViewportHeight } from '@/hooks/useViewport';

export default (viewportHeight: ViewportHeight): number => {
  if (viewportHeight.is('medium')) {
    return GET_MAX_TRANSACTIONS_FROM_API_M_SCREEN;
  }

  if (viewportHeight.is('large')) {
    return GET_MAX_TRANSACTIONS_FROM_API_L_SCREEN;
  }

  return GET_MAX_TRANSACTIONS_FROM_API;
};
