import React, { ReactElement } from 'react';
import { makeStyles } from '@material-ui/core/styles';

type IProps = {
  errors: string[];
};

const useStyles = makeStyles((theme) => ({
  box: {
    display: 'flex',
    flexDirection: 'column',
  },
  list: {
    margin: 0,
    paddingLeft: theme.spacing(3),
  },
}));

function ErrorList({ errors }: IProps): ReactElement {
  const classes = useStyles();

  return (
    <div className={classes.box}>
      <ul className={classes.list}>
        {errors.map((message) =>
          message.split(',').map((messageItem) => <li key={messageItem}>{messageItem}</li>),
        )}
      </ul>
    </div>
  );
}

export default ErrorList;
