export const TRANSACTION_STATUS_SUCCEEDED = 'SUCCEEDED';

export const supportedExportFormats = {
  CSV: 'csv',
  PDF: 'pdf',
};

export const PaymentType = {
  payconiq: 'Payconiq',
  voucher: 'Voucher',
  hybrid: 'Hybrid',
};

export const PayconiqTypes = [PaymentType.payconiq, PaymentType.hybrid];
export const VoucherTypes = [PaymentType.voucher, PaymentType.hybrid];

export const Schemes = {
  BEL_MEAL_VOUCHER: 'meal',
  BEL_ECO_VOUCHER: 'eco',
  BEL_GIFT_VOUCHER: 'gift',
};
