import { createContext } from 'react';
import { IAuthContext, IAuthState } from '@/types/auth';

export const initStateAuth: IAuthState = {
  isAuthenticated: false,
  user: null,
  fetching: false,
};

export const context: IAuthContext = {
  ...initStateAuth,
  getUser: () => {},
  signIn: () => {},
  signOut: () => {},
  resetData: () => {},
};

const authContext = createContext<IAuthContext>(context);

export default authContext;
