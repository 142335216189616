import React, { useEffect, useMemo, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import usePaymentProfiles from '@/hooks/usePaymentProfiles';
import { PRODUCTS } from '@/constants/common';
import FloatingQrDownloadBanner from '@/components/FloatingQrDownloadBanner';
import paths from '@/router/paths';
import useCookie from '@/hooks/useCookie';

function PreDefinedStickerBanner() {
  const { t } = useTranslation();
  const { selectedPaymentProfile } = usePaymentProfiles();
  const { get, set } = useCookie();
  const [isDismissed, setDismiss] = useState(false);

  const isPreDefinedBannerVisible = useMemo(() => {
    const { productName } = selectedPaymentProfile || {};
    return !!productName && !isDismissed && PRODUCTS.PRE_DEFINED_STICKER === productName;
  }, [selectedPaymentProfile, isDismissed]);

  useEffect(() => {
    setDismiss(!!get('DISMISSED_BANNER'));
  }, [get]);

  const handleDismiss = () => {
    set('DISMISSED_BANNER', true);
    setDismiss(true);
  };

  return (
    <FloatingQrDownloadBanner
      show={isPreDefinedBannerVisible}
      text={<Trans t={t} i18nKey="downloadQR.message.banner.predefined" />}
      actions={
        <Grid container alignItems="center" justifyContent="space-around">
          <Button color="inherit" size="large" variant="text" onClick={handleDismiss}>
            {t('button.dismiss')}
          </Button>
          <Button
            component={RouterLink}
            color="inherit"
            size="large"
            onClick={handleDismiss}
            to={paths.downloadQR}
            rel="noopener"
          >
            {t('button.startNow')}
          </Button>
        </Grid>
      }
    />
  );
}

export default PreDefinedStickerBanner;
