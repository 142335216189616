import { useRef } from 'react';

type ViewportHeightSize = 'large' | 'medium';

export type ViewportHeight = {
  is: (size: ViewportHeightSize) => boolean;
};

type UseViewport = {
  viewportHeight: ViewportHeight;
};

function useViewport(): UseViewport {
  const getViewportHeight = () => {
    return window && window.innerHeight;
  };

  const viewportHeight = useRef({
    is: (size: ViewportHeightSize) => {
      const height = getViewportHeight();

      switch (size) {
        case 'medium':
          return height >= 1000 && height < 1400;
        case 'large':
          return height >= 1400;
        default:
          return false;
      }
    },
  });

  return { viewportHeight: viewportHeight.current };
}

export default useViewport;
