import theme from '@/styles/theme';

export default {
  emptyPageHeight: {
    height: 100,
  },
  loadSectionHeight: {
    height: 50,
  },
  expand: {
    width: '5%',
    padding: 0,
    [theme.breakpoints.up('sm')]: {
      width: '3%',
    },
  },
  reverse: {
    backgroundColor: theme.palette.background.item,
    '&:nth-child(even)': {
      backgroundColor: 'unset',
    },
  },
  expandedRow: {
    '& td': {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    '& p + p': {
      marginTop: theme.spacing(0.5),
    },
  },
  expandedSection: {
    position: 'sticky',
    left: 5,
    lineHeight: '30px',
  },
  amountWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  currencyIcon: {
    marginLeft: theme.spacing(1),
    marginBottom: 2,
  },
  hidden: {
    display: 'none',
  },
  statusWrapper: {
    marginBottom: theme.spacing(2),
  },
  statusSucceeded: {
    color: theme.palette.background.active,
  },
};
