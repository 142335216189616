/* eslint-disable no-param-reassign */
import { useEffect, useState, useRef } from 'react';

type CookieOptions = {
  path?: string;
};

type GetCookie = (key: string) => string;
type SetCookie = (key: string, value: string, options?: CookieOptions) => void;

type UseCookie = () => {
  get: GetCookie;
  set: SetCookie;
};

const useCookie: UseCookie = () => {
  const cookies = useRef<Record<string, string>>({});
  const [incrementor, setIncrementor] = useState(0);

  useEffect(() => {
    cookies.current = decodeURIComponent(document.cookie)
      .split(';')
      .reduce((prev, current) => {
        const [key, value] = current.split('=');
        return {
          ...prev,
          [key.trim()]: value,
        };
      }, {});
  }, [incrementor]);

  const get: GetCookie = (key) => {
    return cookies.current[key];
  };

  const set: SetCookie = (key, value, options = { path: '/' }) => {
    const optionsString = Object.entries(options).reduce(
      (prev, [optionKey, optionValue], currentIndex, arr) => {
        prev += `${optionKey}=${optionValue}`;
        if (currentIndex !== arr.length - 1) {
          prev += ';';
        }

        return prev;
      },
      '',
    );

    document.cookie = `${key}=${value};${optionsString}`;
    setIncrementor(incrementor + 1);
  };

  return { get, set };
};

export default useCookie;
