import isEmpty from '@/lib/isEmpty';

type EventEmitter = {
  events: Record<string, CallableFunction[]>;
  on: (eventName: string, fn: CallableFunction) => void;
  off: (eventName: string, fn?: CallableFunction) => void;
  emit: (eventName: string, data?: unknown) => void;
};

const eventEmitter: EventEmitter = {
  events: {},

  on(eventName, fn) {
    if (isEmpty(this.events[eventName])) {
      this.events[eventName] = [];
    }
    this.events[eventName].push(fn);
  },

  off(eventName, fn) {
    if (this.events[eventName]) {
      this.events[eventName] = this.events[eventName].filter(
        (item: CallableFunction) => item !== fn,
      );
    }
  },

  emit(eventName, data) {
    if (this.events[eventName]) {
      this.events[eventName].forEach((fn) => {
        fn(data);
      });
    }
  },
};

export default eventEmitter;
