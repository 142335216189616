import React, { ReactElement, Suspense, useMemo, useEffect, useCallback } from 'react';

// FallbackComponent
type IFallbackComponentProps = {
  mountedElement: ReactElement;
  callBack: () => void;
};

function FallbackComponent({ mountedElement, callBack }: IFallbackComponentProps): ReactElement {
  useEffect(() => () => callBack(), [callBack]);

  return mountedElement;
}

// RouterFallback
type IRouterFallbackProps = {
  children: ReactElement;
};

function RouterFallback({ children }: IRouterFallbackProps): ReactElement {
  const fallback = React.useRef(<div />);

  const renderChildren = useMemo(() => {
    return children;
  }, [children]);

  const updateFallback = useCallback(() => {
    // The last mounted component will be used as a fallback for the next lazy-loaded page, which hasn't been displayed yet.
    fallback.current = renderChildren;
  }, [renderChildren]);

  return (
    <Suspense
      fallback={<FallbackComponent mountedElement={fallback.current} callBack={updateFallback} />}
    >
      {renderChildren}
    </Suspense>
  );
}

export default RouterFallback;
