import { createBrowserHistory } from 'history';
import { trackHistory } from '@/utils/analytics';

const history = createBrowserHistory();

// google analytics
history.listen((location) => {
  trackHistory(location);
});

export default history;
