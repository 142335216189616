export function browserStorageSet(key: string, value: unknown): void {
  if (localStorage) localStorage.setItem(key, JSON.stringify(value));
}

export function browserStorageSetArray(arr: { key: string; value: unknown }[]): void {
  if (localStorage) {
    arr.forEach(({ key, value }) => localStorage.setItem(key, JSON.stringify(value)));
  }
}

export function browserStorageGet(key: string): unknown {
  const data = localStorage ? localStorage.getItem(key) : null;
  if (data) {
    let parsed: unknown;
    try {
      parsed = JSON.parse(data);
    } catch (e) {
      parsed = data;
    }
    return parsed;
  }

  return data;
}

export function browserStorageRemove(keys: string | string[]): void {
  if (localStorage) {
    const storageKeys = Array.isArray(keys) ? keys : [keys];
    storageKeys.forEach((key) => localStorage.removeItem(key));
  }
}
