type TSDocument = Document & {
  mozFullScreenEnabled: boolean;
  webkitFullscreenEnabled: boolean;
  mozCancelFullScreen(): Promise<void>;
  webkitExitFullscreen(): Promise<void>;
  msExitFullscreen(): Promise<void>;
  mozFullScreenElement: Element | null;
  webkitFullscreenElement: Element | null;
  msFullscreenElement: Element | null;
};

type TSDocumentElement = HTMLElement & {
  mozRequestFullScreen(): Promise<void>;
  webkitRequestFullScreen(): Promise<void>;
  msRequestFullscreen(): Promise<void>;
};

const tsDocument = document as TSDocument;
export const fullscreenModeAvailable =
  tsDocument.fullscreenEnabled ||
  tsDocument.mozFullScreenEnabled ||
  tsDocument.webkitFullscreenEnabled;

type CancelFullScreen = () => Promise<void>;
const getCancelFullScreen = (doc: TSDocument): CancelFullScreen =>
  (!!doc.exitFullscreen && doc.exitFullscreen.bind(doc)) ||
  (!!doc.mozCancelFullScreen && doc.mozCancelFullScreen.bind(doc)) ||
  (!!doc.webkitExitFullscreen && doc.webkitExitFullscreen.bind(doc)) ||
  (!!doc.msExitFullscreen && doc.msExitFullscreen.bind(doc));

type RequestFullScreen = (options?: FullscreenOptions) => Promise<void>;
const getRequestFullScreen = (docEl: TSDocumentElement): RequestFullScreen =>
  (!!docEl.requestFullscreen && docEl.requestFullscreen.bind(docEl)) ||
  (!!docEl.mozRequestFullScreen && docEl.mozRequestFullScreen.bind(docEl)) ||
  (!!docEl.webkitRequestFullScreen && docEl.webkitRequestFullScreen.bind(docEl)) ||
  (!!docEl.msRequestFullscreen && docEl.msRequestFullscreen.bind(docEl));

function toggleFullScreen(): void {
  const doc = document as TSDocument;
  const docEl = document.documentElement as TSDocumentElement;

  const cancelFullScreen = getCancelFullScreen(doc);
  const requestFullScreen = getRequestFullScreen(docEl);

  if (
    !doc.fullscreenElement &&
    !doc.mozFullScreenElement &&
    !doc.webkitFullscreenElement &&
    !doc.msFullscreenElement
  ) {
    requestFullScreen().catch((e) => Promise.reject(e));
  } else {
    cancelFullScreen().catch((e) => Promise.reject(e));
  }
}

export default toggleFullScreen;
