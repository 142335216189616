import React, { ReactElement } from 'react';
import clsx from 'clsx';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

type IProps = {
  children?: ReactElement;
  isLoading: boolean;
  loaderStyles?: string;
  loader?: ReactElement;
} & typeof defaultProps;

const defaultProps = {
  children: <div />,
  loaderStyles: {},
  loader: <CircularProgress />,
};

const useStyles = makeStyles(() => ({
  center: {
    textAlign: 'center',
  },
}));

function Loader(props: IProps): ReactElement {
  const { children, isLoading, loaderStyles, loader } = props;
  const classes = useStyles();

  return isLoading ? (
    <div data-testid="loader" className={clsx(classes.center, loaderStyles)}>
      {loader}
    </div>
  ) : (
    children
  );
}

Loader.defaultProps = defaultProps;

export default Loader;
