import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useHistory, useLocation } from 'react-router-dom';
import paths from '@/router/paths';
import useCustomEvent from '@/hooks/useCustomEvent';
import { SESSION_EXPIRED_EVENT } from '@/constants/customEventNames';
import BROWSER_STORAGE_KEYS from '@/constants/browserStorageKeys';
import { browserStorageSet } from '@/utils/browserStorage';

function SessionExpiry() {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const handleSessionExpiryError = useCallback(() => {
    browserStorageSet(BROWSER_STORAGE_KEYS.SHOULD_CLEAR_USER, true);
    if (location.pathname !== paths.login) {
      history.replace(paths.login, { from: { pathname: location.pathname } });
    }
    enqueueSnackbar(t('error.sessionExpired'), {
      preventDuplicate: true,
    });
  }, [history, location.pathname, t, enqueueSnackbar]);

  useCustomEvent(SESSION_EXPIRED_EVENT, handleSessionExpiryError);

  return null;
}

export default SessionExpiry;
