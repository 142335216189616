import React, { ReactElement, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { AutocompleteCloseReason as MUIAutocompleteCloseReason } from '@material-ui/lab/Autocomplete';
import { IPaymentProfile, ChangePaymentProfilesHandler } from '@/types/paymentProfile';

import PaymentProfileAutocomplete from '@/components/PaymentProfiles/PaymentProfileAutocomplete';
import { UnknownObject } from '@/types/common';

type MUIModalCloseReason = 'backdropClick' | 'escapeKeyDown';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
  },
  paper: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 2, 3),

    '&:focus': {
      outline: 'none',
    },

    width: '50%',
    height: '65vh',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),

    '& > button': {
      marginLeft: 'auto',
    },
  },
}));

type Props = {
  open: boolean;
  onClose: () => void;
  onChange: ChangePaymentProfilesHandler;
  options: IPaymentProfile[];
  settings: {
    canClose: boolean;
    showAllPaymentProfileOption: boolean;
    autoFocus: boolean;
  };
};

function PaymentProfileModal({ open, onClose, onChange, options, settings }: Props): ReactElement {
  const classes = useStyles();

  const { canClose, showAllPaymentProfileOption, autoFocus } = settings;

  const { t } = useTranslation();

  const handleClose = (
    event: ChangeEvent<UnknownObject>,
    reason: MUIAutocompleteCloseReason | MUIModalCloseReason,
  ) => {
    if (['blur', 'escape', 'backdropClick'].includes(reason)) {
      return;
    }

    onClose();
  };

  const handleChange = (value: IPaymentProfile | null) => {
    onChange(value);
  };

  return (
    <div data-testid="modal">
      {options && Array.isArray(options) && options.length > 0 && (
        <Modal
          open={open}
          onClose={handleClose}
          className={classes.modal}
          disableRestoreFocus
          disableEscapeKeyDown
        >
          <div className={classes.paper}>
            <div className={classes.title}>
              <Typography component="div">
                {t('pp.firstLogin.title')} (<strong>{options.length}</strong>)
                <Typography variant="caption" component="p">
                  {t('pp.firstLogin.text')}
                </Typography>
              </Typography>
              {canClose && (
                <IconButton onClick={() => onClose()}>
                  <CloseIcon />
                </IconButton>
              )}
            </div>
            <PaymentProfileAutocomplete
              open={open}
              onClose={handleClose}
              onChange={handleChange}
              options={options}
              elevation={0}
              showAllPaymentProfileOption={showAllPaymentProfileOption}
              autoFocus={autoFocus}
            />
          </div>
        </Modal>
      )}
    </div>
  );
}

export default PaymentProfileModal;
