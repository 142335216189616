import { makeStyles } from '@material-ui/core/styles';
import transactionPageStyles from '@/components/Transactions/styles';

export default makeStyles((theme) => ({
  table: {
    tableLayout: 'fixed',
  },
  currency: {
    position: 'relative',
    top: 2,
  },
  time: {
    [theme.breakpoints.down('md')]: {
      width: '7%',
      padding: 5,
      whiteSpace: 'normal',
      wordWrap: 'break-word',
    },
    width: '8%',
  },
  amount: {
    width: '8%',
    fontVariantNumeric: 'tabular-nums',
    [theme.breakpoints.down('md')]: {
      width: '13%',
      whiteSpace: 'normal',
      wordWrap: 'break-word',
    },
  },
  name: {
    width: '16%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  type: {
    padding: 0,
    width: '8%',
    [theme.breakpoints.down('md')]: {
      width: '10%',
    },
  },
  status: {
    padding: 0,
    width: '8%',
  },
  message: {
    width: '8%',
    ...theme.mixins.noWrap,
  },
  ...transactionPageStyles,
}));
