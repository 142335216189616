import { ReactElement, useState, useCallback, useEffect, useMemo } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import isBefore from 'date-fns/isBefore';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { ReactComponent as TCLogo } from '@/assets/images/logo/tcLogo.svg';
import { publicPaths } from '@/router/paths';
import useAuth from '@/hooks/useAuth';
import BROWSER_STORAGE_KEYS from '@/constants/browserStorageKeys';
import { browserStorageGet, browserStorageSet } from '@/utils/browserStorage';
import tcContext from '@/contexts/tcContext';
import { T_AND_C_LINKS } from '@/constants/termsAndConditionsLinks';
import { IS_TERMS_AND_CONDITIONS_MODAL_ON } from '@/constants/features';
import getPQCountry from '@/utils/getPQCountry';
import getLanguage from '@/utils/getLanguage';
import { ITCContext } from '@/types/tc';

const constantContextValue: ITCContext = { showTCModal: false };

// Sunday 30th June 2024, Reminder: Javascript month is a zero-based value.
const END_OF_ANNOUNCEMENT: Date = new Date(2024, 5, 30);

const LayoutLandingPages = Object.keys(publicPaths).reduce(
  (acc: string[], crr) => [...acc, publicPaths[crr]],
  [],
);

const useStyles = makeStyles((theme) => ({
  tcDialog: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: '600px',
    },
    '& .MuiDialog-paper': {
      borderRadius: 0,
      [theme.breakpoints.down('sm')]: {
        marginInline: theme.spacing(2.5),
        width: 'calc(100% - 40px)',
      },
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(0, 4, 5),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0, 2, 5),
      },
    },
  },
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    padding: theme.spacing(3, 2),
  },
  closeButton: {
    margin: theme.spacing(0, 2),
    padding: theme.spacing(0),
    position: 'absolute',
    right: 0,
    [theme.breakpoints.down('sm')]: {
      top: theme.spacing(2),
    },
  },
  content: {
    padding: theme.spacing(1, 3, 4, 6),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1, 2.5, 4),
    },
  },
  action: {
    width: 136,
    height: 40,
  },
}));

type Props = {
  children: ReactElement;
};

function TCModal({ children }: Props): ReactElement {
  const classes = useStyles();
  const { t } = useTranslation();
  const { pathname }: { pathname: string } = useLocation();
  const { user } = useAuth();
  const pqCountry = getPQCountry(user);
  const language = getLanguage();
  const [showTCModal, setShowTCModal] = useState(false);

  const contextValue: ITCContext = useMemo(
    () => ({
      showTCModal,
    }),
    [showTCModal],
  );

  useEffect(() => {
    if (user) {
      // const isAdmin = (getParsedToken()?.authorities || []).includes(
      //   systemRoles.admin as Authority,
      // );
      const isLuxUser = pqCountry === 'LUX';
      const isPrimaryLayout = !LayoutLandingPages.includes(pathname);
      const hasConfirmedTC = browserStorageGet(BROWSER_STORAGE_KEYS.TC);
      const isBeforeEndOfAnnouncement = isBefore(new Date(), END_OF_ANNOUNCEMENT);
      setShowTCModal(isPrimaryLayout && !hasConfirmedTC && isBeforeEndOfAnnouncement && isLuxUser);
    }
  }, [user, pathname, pqCountry]);

  const onConfirm = useCallback(() => {
    setShowTCModal(false);
    browserStorageSet(BROWSER_STORAGE_KEYS.TC, true);
  }, []);

  return IS_TERMS_AND_CONDITIONS_MODAL_ON ? (
    <>
      <tcContext.Provider value={contextValue}>{children}</tcContext.Provider>
      <Dialog
        open={showTCModal}
        fullWidth
        keepMounted={false}
        maxWidth="sm"
        className={classes.tcDialog}
      >
        <DialogTitle disableTypography className={classes.dialogTitle}>
          <TCLogo />
          <IconButton aria-label="close" className={classes.closeButton} onClick={onConfirm}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.content}>
          <Typography component="p">
            <Trans
              i18nKey="pop_up.phishing.message"
              components={{
                1: (
                  <Link
                    href={T_AND_C_LINKS[pqCountry]?.generalTC[language]}
                    target="_blank"
                    rel="noopener"
                  />
                ),
                contact: <Link component={RouterLink} to="/support" onClick={onConfirm} />,
                bold: <strong />,
              }}
            />
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            data-testid="TCConfirmButton"
            className={classes.action}
            onClick={onConfirm}
          >
            {t('button.ok')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  ) : (
    <tcContext.Provider value={constantContextValue}>{children}</tcContext.Provider>
  );
}

export default TCModal;
