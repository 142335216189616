const SUPPORTED_BROWSERS = {
  safari: 7,
  chrome: 65,
  firefox: 60,
  ie: 9,
  edge: 14,
  samsung: 7.2,
};

export default SUPPORTED_BROWSERS;
