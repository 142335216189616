import { InputBaseComponentProps } from '@material-ui/core';
import { Address } from '@/types/common';

export const API_TIMEOUT = 20000;
export const API_DOCUMENT_UPLOAD_TIMEOUT = 50000;
export const IS_DEV_ENV = process.env.NODE_ENV === 'development';
export const API_BASE_PATH = 'merchant-portal/api/';
export const DEV_API_HOST = `/${API_BASE_PATH}`;
export const GET_MAX_TRANSACTIONS_FROM_API = 20;
export const GET_MAX_TRANSACTIONS_FROM_API_M_SCREEN = 35;
export const GET_MAX_TRANSACTIONS_FROM_API_L_SCREEN = 45;
export const TIME_ZONE = 'Europe/Amsterdam';
export const DATE_FORMAT = 'dd/MM/yyyy';
export const DATE_FORMAT_SHORT = 'dd/MM/yy';
export const TIME_FORMAT = 'HH:mm';
export const DAY_IN_MILLISECONDS = 1000 * 3600 * 24;
export const MAX_DATE_RANGE_IN_YEARS = 10;
export const PQ_COUNTRY = {
  PQI: 'PQI',
  NLD: 'NLD',
  BEL: 'BEL',
  LUX: 'LUX',
  DEU: 'DEU',
};

export const LANGUAGE = {
  EN: 'en',
  NL: 'nl',
  FR: 'fr',
  DE: 'de',
};

export const PRODUCTS = {
  STICKER: 'ON_STICKER_NON_INTEGRATED',
  PRE_DEFINED_STICKER: 'FIXED_AMOUNT_STICKER',
  GO: 'GO',
};

export const PROFILES_ALLOWED_TO_DOWNLOAD_QR = [PRODUCTS.STICKER, PRODUCTS.PRE_DEFINED_STICKER];

export const RESTART_TIMEOUT = 300;
export const DEFAULT_PAGE_SIZE = 25;

export const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '';
export const GOOGLE_SUCCESS_STATUS = 'OK';

export const DEFAULT_INPUT_PROPS: InputBaseComponentProps = {
  autoComplete: 'off',
  spellCheck: 'false',
  autoCorrect: 'false',
  autoCapitalize: 'off',
};

export const emptyAddress: Address = {
  no: '',
  street: '',
  postalCode: '',
  city: '',
  country: '',
};
