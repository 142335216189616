import { ReactElement, useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Link from '@material-ui/core/Link';
import useAuth from '@/hooks/useAuth';
import { PQ_COUNTRY } from '@/constants/common';
import { T_AND_C_LINKS, MINIMUM_ACCEPTED_GAP } from '@/constants/termsAndConditionsLinks';
import getPQCountry from '@/utils/getPQCountry';
import getLanguage from '@/utils/getLanguage';

const useStyles = makeStyles((theme) => ({
  gap: {
    flexGrow: 1,
  },
  list: {
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(1.5),
    paddingBottom: 0,
  },
  listItem: {
    display: 'list-item',
    '&:before': {
      content: '"\\2022"',
      display: 'inline-block',
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightBold,
      width: theme.typography.pxToRem(14),
      marginLeft: -theme.typography.pxToRem(14),
    },
  },
}));

function TermsAndConditions(): ReactElement {
  const classes = useStyles();
  const { t } = useTranslation();
  const { user } = useAuth();
  const pqCountry = getPQCountry(user);
  const language = getLanguage();

  const gap = useRef<HTMLDivElement>(null);
  const [minHeight, setMinHeight] = useState(0);
  const [showGap, setShowGap] = useState(true);

  useEffect(() => {
    if (user?.pqCountry && gap.current) {
      setMinHeight(gap.current.offsetHeight || 0);
      setShowGap(gap.current.offsetHeight >= MINIMUM_ACCEPTED_GAP);
    }
  }, [user]);

  const areTCLinksShown = pqCountry === PQ_COUNTRY.BEL || pqCountry === PQ_COUNTRY.LUX;
  return (
    <>
      {showGap && (
        <Box ref={gap} flexGrow="1" minHeight={minHeight} display={{ xs: 'none', md: 'block' }} />
      )}
      {areTCLinksShown && (
        <List className={classes.list}>
          <ListItem disableGutters className={classes.listItem}>
            <Link
              href={T_AND_C_LINKS[pqCountry]?.generalTC[language]}
              target="_blank"
              rel="noopener"
            >
              {t('support.MG_TC.title')}
            </Link>
          </ListItem>
          <ListItem disableGutters className={classes.listItem}>
            <Link
              href={T_AND_C_LINKS[pqCountry]?.privacyStatement[language]}
              target="_blank"
              rel="noopener"
            >
              {t('support.privacy_statement.title')}
            </Link>
          </ListItem>
        </List>
      )}
    </>
  );
}

export default TermsAndConditions;
