import { createTheme, Theme } from '@material-ui/core/styles';

import typography from '@/styles/typography';
import createPalette from '@/styles/palette';
import breakpoints from '@/styles/breakpoints';
import mixins from '@/styles/mixins';
import fonts from '@/styles/fonts';

const theme: Theme = createTheme({
  breakpoints,
});

const palette = createPalette(theme);

export default createTheme({
  palette,
  typography: typography(theme),
  mixins: mixins(theme),
  breakpoints,
  overrides: {
    MuiAppBar: {
      root: {
        boxShadow: 'none',
      },
    },
    MuiLink: {
      underlineAlways: {
        '&:hover, &:focus, &:active': {
          textDecoration: 'none',
        },
      },
    },
    MuiRadio: {},
    MuiButton: {
      root: {
        borderRadius: 0,
        textTransform: 'none',
      },
      containedPrimary: {
        backgroundColor: '#0b985c',
        color: theme.palette.common.white,
      },
      textPrimary: {
        color: '#0b985c',
      },
    },
    MuiIconButton: {
      colorPrimary: {
        color: '#0b985c',
      },
    },
    MuiFormLabel: {
      root: {
        '&.Mui-disabled': {
          color: palette.text?.secondary,
        },
      },
    },
    MuiToolbar: {
      gutters: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
        },
      },
    },
    MuiCssBaseline: {
      '@global': {
        '@font-face': fonts,
      },
    },
    MuiTableHead: {
      root: {
        backgroundColor: palette.background?.tableHead,
        '& tr > th': {
          fontWeight: 'bold',
        },
      },
    },
    MuiTableRow: {
      root: {
        '& th, td': {
          color: palette.text?.primary,
        },
        '&:nth-child(even)': {
          backgroundColor: '#fbf8f5',
        },
      },
    },
    MuiTableCell: {
      root: {
        padding: theme.spacing(1, 2),
        fontSize: theme.typography.pxToRem(14),
      },
    },
  },
});
