import { ReactElement, ComponentType } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { IRouteGuarded } from '@/router/types';
import paths from '@/router/paths';
import useAuth from '@/hooks/useAuth';
import { hasToken } from '@/utils/authService';

export function PrivateRoute({ children, ...props }: RouteProps): ReactElement {
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated || !hasToken()) {
    const options =
      props.location?.pathname !== paths.logout ? { state: { from: props.location } } : {};
    return <Redirect to={{ pathname: paths.login, ...options }} />;
  }

  return <Route {...props}>{children}</Route>;
}

function ProtectedRoute({
  middleware = [],
  redirectTo,
  component,
  ...r
}: IRouteGuarded): ReactElement {
  const finalProps = { ...r };
  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  if (middleware) {
    for (const currentMiddleware of middleware) {
      const response = currentMiddleware && currentMiddleware(finalProps);
      if (response?.action === 'redirect') {
        return (
          <Route {...finalProps}>
            <Redirect to={{ pathname: response.path, state: { from: finalProps.path } }} />
          </Route>
        );
      }
    }
  }

  return component ? (
    <Route {...finalProps} component={component() as ComponentType} />
  ) : (
    <div data-testid="fallback-div" />
  );
}

export default ProtectedRoute;
