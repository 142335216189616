import { getRoute } from '@/router/paths';

export const sofiaProLight = {
  fontFamily: 'Sofia Pro',
  fontStyle: 'normal',
  fontWeight: 300,
  src: `
    local('Sofia Pro Light'), local('SofiaProLight'),
    url(${getRoute('/fonts/SofiaProLight.woff2')}) format('woff2')
  `,
};

export const sofiaProRegular = {
  fontFamily: 'Sofia Pro',
  fontStyle: 'normal',
  fontWeight: 400,
  src: `
    local('Sofia Pro Regular'), local('SofiaProRegular'),
    url(${getRoute('/fonts/SofiaProRegular.woff2')}) format('woff2')
  `,
};

export const sofiaProMedium = {
  fontFamily: 'Sofia Pro',
  fontStyle: 'normal',
  fontWeight: 500,
  src: `
    local('Sofia Pro Medium'), local('SofiaProMedium'),
    url(${getRoute('/fonts/SofiaProMedium.woff2')}) format('woff2')
  `,
};
export const sofiaProBold = {
  fontFamily: 'Sofia Pro',
  fontStyle: 'normal',
  fontWeight: 700,
  src: `
    local('Sofia Pro Bold'), local('SofiaProBold'),
    url(${getRoute('/fonts/SofiaProBold.woff2')}) format('woff2')
  `,
};

export const sofiaProBlack = {
  fontFamily: 'Sofia Pro',
  fontStyle: 'normal',
  fontWeight: 900,
  src: `
    local('Sofia Pro Black'), local('SofiaProBlack'),
    url(${getRoute('/fonts/SofiaProBlack.woff2')}) format('woff2')
  `,
};

export default [sofiaProRegular, sofiaProBlack, sofiaProMedium, sofiaProBold, sofiaProLight];
