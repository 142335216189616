import BROWSER_STORAGE_KEYS from '@/constants/browserStorageKeys';
import { browserStorageSet, browserStorageRemove, browserStorageGet } from '@/utils/browserStorage';
import { IAuth } from '@/types/auth';

export function setToken(data: IAuth): void {
  browserStorageSet(BROWSER_STORAGE_KEYS.USER_AUTH, data);
  browserStorageSet(BROWSER_STORAGE_KEYS.IS_LOGGED_IN, true);
}

export function getParsedToken(): IAuth {
  return browserStorageGet(BROWSER_STORAGE_KEYS.USER_AUTH) as IAuth;
}

export function removeToken(): void {
  browserStorageRemove(BROWSER_STORAGE_KEYS.USER_AUTH);
  browserStorageRemove(BROWSER_STORAGE_KEYS.IS_LOGGED_IN);
}

export function hasToken(): boolean {
  return !!browserStorageGet(BROWSER_STORAGE_KEYS.IS_LOGGED_IN);
}
