import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Euro from '@material-ui/icons/Euro';
import transactionPageStyles from '@/components/Transactions/styles';
import { PaymentType, PayconiqTypes, VoucherTypes, Schemes } from '@/constants/transactions';
import { convertCentsToEuro } from '@/utils/stringUtil';

const useStyles = makeStyles(() => transactionPageStyles);

function ExpandedTransaction({ transaction, colSpan, isMobile, isSmallScreen }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const variant = isMobile ? 'caption' : 'body2';

  const {
    paymentId,
    payconiqPaymentId,
    voucherPaymentId,
    amount,
    payconiqAmount,
    voucherAmount,
    type,
    vasDetails,
    name,
    description,
    creditorName,
  } = transaction;

  const showPayconiq = PayconiqTypes.includes(type);
  const showVoucher = VoucherTypes.includes(type);

  const amountEuro = convertCentsToEuro(amount);
  const payconiqAmountEuro = convertCentsToEuro(payconiqAmount);
  const voucherAmountEuro = convertCentsToEuro(voucherAmount);

  let vasDetail;
  let brandNameKey = '';
  if (Array.isArray(vasDetails) && vasDetails.length > 0) {
    [vasDetail] = vasDetails; // for now, there can be only one voucher per transaction.
    const scheme = Schemes[vasDetail.schemeId];
    brandNameKey = `voucher_program.${scheme}`;
  }

  return (
    <TableRow className={clsx(classes.expandedRow, classes.reverse)}>
      <TableCell colSpan={colSpan}>
        <div className={classes.expandedSection}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={3}>
              {showPayconiq && (
                <Typography variant={variant} component="p" className={classes.amountWrapper}>
                  <strong>{PaymentType.payconiq}:</strong>
                  <Euro fontSize="inherit" className={classes.currencyIcon} />
                  &nbsp;
                  {payconiqAmountEuro || amountEuro || '-'}
                </Typography>
              )}
              {showVoucher && (
                <Typography variant={variant} component="p" className={classes.amountWrapper}>
                  <strong>
                    {vasDetail.providerName}-{t(brandNameKey)}:
                  </strong>
                  <Euro fontSize="inherit" className={classes.currencyIcon} />
                  &nbsp;
                  {voucherAmountEuro || amountEuro || '-'}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant={variant} component="p">
                <strong>{t('transactions.message')}:</strong> {description || '-'}
              </Typography>
            </Grid>
            <Grid item xs={12} className={!isMobile && classes.hidden}>
              <Typography variant={variant} component="p">
                <strong>{t('transactions.shop')}:</strong> {creditorName || '-'}
              </Typography>
            </Grid>
            <Grid item xs={12} className={(!isMobile || isSmallScreen) && classes.hidden}>
              <Typography variant={variant} component="p">
                <strong>{t('transactions.name')}:</strong> {name || '-'}
              </Typography>
            </Grid>
            <Grid item xs={12} className={!isMobile && classes.hidden}>
              <Typography variant={variant} component="p" className={classes.statusWrapper}>
                <strong>{t('transactions.status.0')}:</strong>&nbsp;
                <span className={classes.statusSucceeded}>
                  {t('transactions.status.succeeded')}
                </span>
              </Typography>
            </Grid>
            <Grid item xs={12} md={5}>
              <Typography variant={variant} component="p">
                <strong>{t('transactions.transactionID')}:</strong> {paymentId || '-'}
              </Typography>
              {type === PaymentType.hybrid && (
                <>
                  <Typography variant={variant} component="p">
                    <strong>{t('transactions.payconiqID')}:</strong> {payconiqPaymentId || '-'}
                  </Typography>
                  <Typography variant={variant} component="p">
                    <strong>{t('transactions.voucherID')}:</strong> {voucherPaymentId || '-'}
                  </Typography>
                </>
              )}
            </Grid>
          </Grid>
        </div>
      </TableCell>
    </TableRow>
  );
}

export default ExpandedTransaction;
