import React, { ReactElement, useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import Link from '@material-ui/core/Link';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Grid from '@material-ui/core/Grid';

import usePaymentProfiles from '@/hooks/usePaymentProfiles';
import useTransaction from '@/hooks/useTransaction';
import { PRODUCTS } from '@/constants/common';
import FloatingQrDownloadBanner from '@/components/FloatingQrDownloadBanner';
import paths from '@/router/paths';
import isEmpty from '@/lib/isEmpty';

function StickerBanner(): ReactElement {
  const { t } = useTranslation();
  const { transactions, isLoading } = useTransaction() || {};

  const { selectedPaymentProfile } = usePaymentProfiles() || {};

  const hasRequiredProfileSelected = useMemo(() => {
    const { productName } = selectedPaymentProfile || {};
    return !!productName && PRODUCTS.STICKER === productName;
  }, [selectedPaymentProfile]);

  return (
    <FloatingQrDownloadBanner
      show={!isLoading && hasRequiredProfileSelected && isEmpty(transactions)}
      text={<Trans t={t} i18nKey="downloadQR.message.0" />}
      actions={
        <Grid container justifyContent="flex-end">
          <Link to={paths.downloadQR} component={RouterLink} color="inherit">
            <ArrowForwardIosIcon fontSize="small" />
          </Link>
        </Grid>
      }
    />
  );
}

export default StickerBanner;
