import { TCLink } from '@/types/tc';

export const T_AND_C_LINKS: TCLink = {
  BEL: {
    generalTC: {
      en: 'https://www.payconiq.be/en/privacy-statement-and-terms-conditions',
      fr: 'https://www.payconiq.be/fr/declaration-de-confidentialite-et-conditions-generales',
      de: 'https://www.payconiq.be/en/privacy-statement-and-terms-conditions',
      nl: 'https://www.payconiq.be/nl/privacyverklaring-en-algemene-voorwaarden',
    },
    privacyStatement: {
      en: 'https://www.payconiq.be/en/privacy-statement-and-terms-conditions',
      fr: 'https://www.payconiq.be/fr/declaration-de-confidentialite-et-conditions-generales',
      de: 'https://www.payconiq.be/en/privacy-statement-and-terms-conditions',
      nl: 'https://www.payconiq.be/nl/privacyverklaring-en-algemene-voorwaarden',
    },
  },
  LUX: {
    generalTC: {
      en: 'https://payconiq.lu/en/terms-and-conditions',
      fr: 'https://payconiq.lu/fr/conditions-generales',
      de: 'https://payconiq.lu/de/geschaftsbedingungen',
      nl: 'https://payconiq.lu/en/terms-and-conditions',
    },
    privacyStatement: {
      en: 'https://payconiq.lu/en/privacy',
      fr: 'https://payconiq.lu/fr/confidentialite',
      de: 'https://payconiq.lu/de/privacy',
      nl: 'https://payconiq.lu/en/privacy',
    },
  },
  get PQI() {
    return this.LUX;
  },
  get NLD() {
    return this.LUX;
  },
  get DEU() {
    return this.LUX;
  },
};

export const MINIMUM_ACCEPTED_GAP = 50; // Based on the design.
