import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Error as ErrorIcon } from '@material-ui/icons';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import WarningOutlined from '@material-ui/icons/WarningOutlined';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import { SnackbarProvider } from 'notistack';

import theme from '@/styles/theme';
import Overlay from '@/components/Overlay';
import ErrorBoundary from '@/components/ErrorBoundary';
import VersionCheck from '@/components/VersionCheck';
import Router from '@/router';
import info from '@/info';

const useStyles = makeStyles((t) => ({
  '@global': {
    '.MuiButton-startIcon-text': {
      marginBottom: -3,
    },
  },
  snackbar: {
    '& .MuiSnackbarContent-root': {
      flexWrap: 'nowrap',
    },
  },
  snackbarIcon: {
    marginRight: t.spacing(1),
  },
  snackbarServerErrorIcon: {
    marginRight: t.spacing(1),
    color: t.palette.secondary.main,
  },
}));

export function AppComponent() {
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SnackbarProvider
        maxSnack={isMobile ? 1 : 3}
        autoHideDuration={3000}
        iconVariant={{
          default: <ErrorOutline fontSize="small" className={classes.snackbarIcon} />,
          success: (
            <CheckCircleOutlineOutlinedIcon fontSize="small" className={classes.snackbarIcon} />
          ),
          error: <WarningOutlined fontSize="small" className={classes.snackbarIcon} />,
          serverError: <ErrorIcon fontSize="small" className={classes.snackbarServerErrorIcon} />,
        }}
        className={classes.snackbar}
        aria-describedby="client-snackbar"
      >
        <Suspense fallback={<Overlay isLoading />}>
          <ErrorBoundary>
            <VersionCheck />
            <Router />
          </ErrorBoundary>
        </Suspense>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default function App() {
  const root = document.getElementById('pq-root');

  if (root === null) throw new Error('No root Element');

  if (process.env.REACT_APP_ENV_NAME !== 'prod') {
    const GIT_LAB_BASE_URL = `https://gitlab.payconiq.io/DEV/merchant/merchant-portal/-/`;
    const [commit, pipeline] = info.split('.');
    // eslint-disable-next-line no-console
    console.log(`REACT_APP_ENV_NAME !== 'prod'`);
    // eslint-disable-next-line no-console
    console.log(`${GIT_LAB_BASE_URL}commit/${commit}`);
    // eslint-disable-next-line no-console
    console.log(`${GIT_LAB_BASE_URL}pipelines/${pipeline}`);
  }

  ReactDOM.render(<AppComponent />, root);
}
