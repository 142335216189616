import { ReactNode, FC } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { ReactComponent as QrcodePhoneIcon } from '@/assets/images/icons/qrcodePhone.svg';
import { isAccessible } from '@/router/router-middleware';
import * as permissions from '@/router/permissions';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#0b985c',
    color: '#fff',
    padding: theme.spacing(2, 2),
    position: 'relative',
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down(767)]: {
      padding: theme.spacing(1, 2),
    },
  },
  textContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  qrcodePhoneIcon: {
    position: 'absolute',
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
    },
  },
  text: {
    marginLeft: theme.spacing(11),
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      width: '60%',
      marginLeft: 0,
    },
  },
  action: {
    marginLeft: 'auto',
  },
}));

interface IProps {
  show: boolean;
  text: ReactNode;
  actions: ReactNode;
}

const FloatingQrDownloadBanner: FC<IProps> = ({ show, text, actions }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div>
      {isAccessible({
        oneOf: permissions.QR_CODE_DOWNLOAD,
      }) === 'pass' &&
        show && (
          <Paper square className={classes.root}>
            <Grid container alignItems="center">
              <Grid item md={9} sm={8} xs={12} className={classes.textContainer}>
                <QrcodePhoneIcon className={classes.qrcodePhoneIcon} />
                <Typography className={classes.text}>{text}</Typography>
              </Grid>
              <Grid item md={3} sm={4} xs={12}>
                <Grid container alignItems="center" direction="row">
                  {isMobile && <Grid item xs={4} />}
                  <Grid item xs={8} sm={12}>
                    <div className={classes.action}>{actions}</div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        )}
    </div>
  );
};

export default FloatingQrDownloadBanner;
