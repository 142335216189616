import { makeStyles } from '@material-ui/core/styles';
import transactionPageStyles from '@/components/Transactions/styles';

export default makeStyles((theme) => ({
  table: {
    tableLayout: 'fixed',
  },
  currency: {
    position: 'relative',
    top: 2,
  },
  date: {
    [theme.breakpoints.down('md')]: {
      width: '14.5%',
      padding: 4,
      whiteSpace: 'normal',
      wordWrap: 'break-word',
    },
    width: '6%',
  },
  time: {
    [theme.breakpoints.down('md')]: {
      width: '6.8%',
      padding: 2,
      whiteSpace: 'normal',
      wordWrap: 'break-word',
    },
    width: '3%',
  },
  amount: {
    width: '6.5%',
    fontVariantNumeric: 'tabular-nums',
    [theme.breakpoints.down('md')]: {
      width: '11.9%',
      padding: 2,
      whiteSpace: 'normal',
      wordWrap: 'break-word',
    },
  },
  transactionId: {
    [theme.breakpoints.down('md')]: {
      width: '21%',
    },
    width: '18%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  shopName: {
    [theme.breakpoints.down('md')]: {
      width: '24%',
    },
    width: '12%',
    ...theme.mixins.noWrap,
  },
  name: {
    width: '7%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      width: '16%',
    },
  },
  reference: {
    [theme.breakpoints.down('md')]: {
      width: '14%',
      padding: '8px 6px',
    },
    width: '16%',
    ...theme.mixins.noWrap,
  },
  type: {
    padding: 0,
    width: '8%',
  },
  status: {
    padding: 0,
    width: '8%',
  },
  ...transactionPageStyles,
}));
