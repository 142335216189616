/* eslint-disable @typescript-eslint/no-misused-promises */
import { useEffect, useRef, useCallback } from 'react';

const DEBOUNCE = 500;

function useScroll(callback: () => Promise<void>, isLoading: boolean): null {
  const scrollX = useRef<number>(0);
  const scrollY = useRef<number>(0);
  const cached = useRef<Event | null>(null);

  const handleScroll = useCallback(
    (event: Event) => {
      if (!cached.current) {
        if (scrollX.current !== window.scrollX) {
          // do nothing if table is scrolled horizontally
          scrollX.current = window.scrollX;
          return;
        }

        if (window.scrollY <= 0 || window.scrollY < scrollY.current) {
          scrollY.current = window.scrollY;
          cached.current = null;
          return;
        }
        setTimeout(async () => {
          if (
            Math.ceil(window.innerHeight + window.scrollY) >= document.body.offsetHeight &&
            !isLoading
          ) {
            await callback();
          }
          cached.current = null;
        }, DEBOUNCE);
      }
      cached.current = event;
    },
    [callback, isLoading],
  );

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  return null;
}

export default useScroll;
