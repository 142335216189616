import { systemPermissions } from '@/constants/permissions';

export const NEW_TRANSACTIONS_VIEW = [systemPermissions.newTransactionsView];
export const ALL_TRANSACTIONS_VIEW = [
  systemPermissions.allTransactionsView,
  systemPermissions.allTransactionsDownload,
];
export const ALL_TRANSACTIONS_DOWNLOAD = [systemPermissions.allTransactionsDownload];
export const TOTALS_VIEW = [systemPermissions.totalsView];
export const MERCHANT_ACCOUNT_VIEW = [systemPermissions.merchantAccountView];
export const MERCHANT_ACCOUNT_REVIEW = [systemPermissions.merchantAccountView];
export const MERCHANT_ACCOUNT_EDIT = [systemPermissions.merchantAccountView];
export const USERS_VIEW = [systemPermissions.usersView, systemPermissions.usersModify];
export const USERS_MODIFY = [systemPermissions.usersModify];
export const SETTINGS_MODIFY = [systemPermissions.settingsModify];
export const QR_CODE_DOWNLOAD = [systemPermissions.qrCodeDownload];
export const SUPPORT_VIEW = [systemPermissions.supportView];
