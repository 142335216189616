import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import RefreshIcon from '@material-ui/icons/Refresh';
import FullScreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import GetApp from '@material-ui/icons/GetApp';

import { REFRESH_TRANSACTIONS_EVENT } from '@/constants/customEventNames';
import toggleFullScreen, { fullscreenModeAvailable } from '@/lib/toggleFullScreen';
import eventEmitter from '@/lib/eventEmitter';
import paths from '@/router/paths';
import { isAccessible } from '@/router/router-middleware';
import * as permissions from '@/router/permissions';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(1),
    position: 'relative',
    paddingRight: 96,
    [theme.breakpoints.up('sm')]: {
      paddingRight: 0,
      textAlign: 'center',
    },
    [theme.breakpoints.up('md')]: {
      marginBlock: theme.spacing(3, 2),
      textAlign: 'center',
    },
  },
  title: {
    fontWeight: theme.typography.fontWeightBlack,
  },
  headerButtonsCotainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    margin: 'auto',
    display: 'flex',
    alignItems: 'center',
    '& button + button, & button + a': {
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
      },
      [theme.breakpoints.up('md')]: {
        marginLeft: theme.spacing(6),
      },
    },
    '& svg': {
      position: 'relative',
      top: -2,
    },
  },
}));

function TransactionsHeader({ showFullScreen, showExportButton, title }) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { t } = useTranslation();
  const [fullscreenMode, setFullscreenMode] = useState(false);

  const refreshTransactions = () => {
    eventEmitter.emit(REFRESH_TRANSACTIONS_EVENT);
  };

  const fullScreenModeSubscribe = () => {
    if (document.fullscreenElement) {
      setFullscreenMode(true);
    } else {
      setFullscreenMode(false);
    }
  };

  useEffect(() => {
    document.addEventListener('fullscreenchange', fullScreenModeSubscribe);

    return () => {
      document.removeEventListener('fullscreenchange', fullScreenModeSubscribe);
    };
  });

  return (
    <div className={classes.root}>
      <Typography variant="h5" color="secondary" className={classes.title}>
        {t(title)}
      </Typography>
      <div className={classes.headerButtonsCotainer}>
        {showFullScreen &&
          fullscreenModeAvailable &&
          (isMobile ? (
            <Tooltip title={t(fullscreenMode ? 'button.fullScreenOff' : 'button.fullScreenOn')}>
              <IconButton color="primary" onClick={toggleFullScreen}>
                {fullscreenMode ? <FullscreenExitIcon /> : <FullScreenIcon />}
              </IconButton>
            </Tooltip>
          ) : (
            <Button
              color="primary"
              onClick={toggleFullScreen}
              startIcon={fullscreenMode ? <FullscreenExitIcon /> : <FullScreenIcon />}
            >
              {t(fullscreenMode ? 'button.fullScreenOff' : 'button.fullScreenOn')}
            </Button>
          ))}

        {isMobile ? (
          <Tooltip title={t('button.refresh')}>
            <IconButton color="primary" onClick={refreshTransactions}>
              <RefreshIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Button color="primary" startIcon={<RefreshIcon />} onClick={refreshTransactions}>
            {t('button.refresh')}
          </Button>
        )}

        {isAccessible({
          oneOf: permissions.ALL_TRANSACTIONS_DOWNLOAD,
        }) === 'pass' &&
          showExportButton &&
          (isMobile ? (
            <Tooltip title={t('button.download')}>
              <IconButton
                component={RouterLink}
                to={paths.exportTransactions}
                aria-label="exportTransactions"
                color="primary"
              >
                <GetApp />
              </IconButton>
            </Tooltip>
          ) : (
            <Button
              component={RouterLink}
              to={paths.exportTransactions}
              color="primary"
              startIcon={<GetApp />}
            >
              {t('button.download')}
            </Button>
          ))}
      </div>
    </div>
  );
}

TransactionsHeader.defaultProps = {
  showFullScreen: false,
  showExportButton: false,
};

TransactionsHeader.propTypes = {
  showFullScreen: PropTypes.bool,
  showExportButton: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

export default TransactionsHeader;
