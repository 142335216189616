import { Theme } from '@material-ui/core';
import { MixinsOptions } from '@material-ui/core/styles/createMixins';

const mixins = (theme: Theme): MixinsOptions => ({
  toolbar: {
    minHeight: 56,
  },
  container: {
    width: '100%',
    padding: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      maxWidth: 1280 - theme.spacing(2) * 2, // remove left and right padding;
    },
  },
  containerLanding: {
    width: '100%',
    marginInline: 'auto',
    padding: theme.spacing(4, 3, 7.5),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(5, 4, 7.5),
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: 1280 - theme.spacing(8) * 2, // remove left and right padding;
      padding: theme.spacing(10, 8),
    },
  },
  noWrap: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  breakWord: {
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
    wordBreak: 'break-word',
  },
});

export default mixins;
