import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';

interface Breakpoints {
  values: { [key in Breakpoint]: number };
}

const breakpoints: Breakpoints = {
  values: {
    xs: 0,
    sm: 768,
    md: 1024,
    lg: 1366,
    xl: 1920,
  },
};

export default breakpoints;
