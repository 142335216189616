import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Paper from '@material-ui/core/Paper';
import Euro from '@material-ui/icons/Euro';
import Loader from '@/components/Loader';
import StatusIcon from '@/components/Transactions/StatusIcon';
import ExpandIcon from '@/components/ExpandIcon';
import TransactionsHeader from '@/components/TransactionsHeader';
import ExpandedTransaction from '@/components/ExpandedTransaction';
import TransactionType from '@/components/TransactionType';
import { REFRESH_TRANSACTIONS_EVENT } from '@/constants/customEventNames';
import { PayconiqTypes, VoucherTypes } from '@/constants/transactions';
import { getDisplayDate, getDisplayTime, convertCentsToEuro } from '@/utils/stringUtil';
import useScroll from '@/hooks/useScroll';
import useCustomEvent from '@/hooks/useCustomEvent';
import useTransaction from '@/hooks/useTransaction';
import isEmpty from '@/lib/isEmpty';
import useStyles from './styles';

function AllTransactions() {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isSmallScreen = useMediaQuery(theme.breakpoints.only('sm'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  const initialTableState = {
    id: null,
    index: null,
  };
  const [expandedRow, setExpandedRow] = useState(initialTableState);
  const { transactions, getTransactions, isLoading } = useTransaction() || {};

  const handleRefreshTransactions = useCallback(async () => {
    await getTransactions();
  }, [getTransactions]);

  useCustomEvent(REFRESH_TRANSACTIONS_EVENT, handleRefreshTransactions);

  const handleScroll = useCallback(async () => {
    await getTransactions('PAGINATION');
  }, [getTransactions]);

  useScroll(handleScroll, isLoading);

  const handleExpandClick = (id, index) => () => {
    setExpandedRow((prevExpandedRow) =>
      prevExpandedRow.id === id
        ? initialTableState
        : {
            id,
            index,
          },
    );
  };

  const getMobileColSpan = () => {
    if (isSmallScreen) return 6;
    return isMobile ? 5 : 9;
  };

  return (
    <>
      <TransactionsHeader title="menu.allTransactions" showExportButton />

      <div className={isEmpty(transactions) ? classes.emptyPageHeight : ''}>
        <TableContainer component={Paper}>
          <Table aria-label="all transactions table" className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell classes={{ root: classes.date }}>{t('transactions.date')}</TableCell>
                <TableCell classes={{ root: classes.time }}>{t('transactions.time')}</TableCell>
                <TableCell classes={{ root: classes.amount }} align="right">
                  {t('transactions.amount')}
                </TableCell>
                {!isMobile && (
                  <TableCell classes={{ root: classes.shopName }}>
                    {t('transactions.shop')}
                  </TableCell>
                )}
                {(isSmallScreen || isDesktop) && (
                  <TableCell classes={{ root: classes.name }}>
                    {t('transactions.firstName')}
                  </TableCell>
                )}
                <TableCell classes={{ root: classes.reference }}>
                  {t('transactions.reference')}
                </TableCell>
                {!isMobile && (
                  <TableCell classes={{ root: classes.type }} align="center">
                    {t('transactions.type')}
                  </TableCell>
                )}
                {!isMobile && (
                  <TableCell classes={{ root: classes.status }} align="center">
                    {t('transactions.status.0')}
                  </TableCell>
                )}
                <TableCell classes={{ root: classes.expand }} />
              </TableRow>
            </TableHead>
            <TableBody>
              {!isEmpty(transactions) &&
                transactions?.map((transaction, index) => {
                  const {
                    paymentId,
                    creationDate,
                    amount,
                    name,
                    reference,
                    creditorName,
                    succeededAt,
                    type,
                    vasDetails,
                  } = transaction;
                  const date = getDisplayDate(succeededAt || creationDate);
                  const time = getDisplayTime(succeededAt || creationDate);
                  const amountInEuros = convertCentsToEuro(amount);
                  const showPayconiq = PayconiqTypes.includes(type);
                  const showVoucher = VoucherTypes.includes(type);
                  return (
                    <React.Fragment key={paymentId}>
                      <TableRow
                        hover
                        className={clsx(
                          expandedRow.index !== null &&
                            index > expandedRow.index &&
                            classes.reverse,
                        )}
                      >
                        <TableCell classes={{ root: classes.date }} title={date}>
                          {date}
                        </TableCell>
                        <TableCell classes={{ root: classes.time }} title={time}>
                          {time}
                        </TableCell>
                        <TableCell
                          classes={{ root: classes.amount }}
                          title={amountInEuros}
                          align="right"
                        >
                          <Euro fontSize="inherit" className={classes.currency} /> {amountInEuros}
                        </TableCell>
                        {!isMobile && (
                          <TableCell classes={{ root: classes.shopName }} title={creditorName}>
                            {creditorName}
                          </TableCell>
                        )}
                        {(isSmallScreen || isDesktop) && (
                          <TableCell classes={{ root: classes.name }} title={name}>
                            {name}
                          </TableCell>
                        )}
                        <TableCell classes={{ root: classes.reference }} title={reference}>
                          {reference}
                        </TableCell>
                        {!isMobile && (
                          <TableCell classes={{ root: classes.type }} align="center" title={type}>
                            <TransactionType
                              showPayconiq={showPayconiq}
                              showVoucher={showVoucher}
                              vasDetails={vasDetails}
                            />
                          </TableCell>
                        )}
                        {!isMobile && (
                          <TableCell
                            classes={{ root: classes.status }}
                            align="center"
                            title={t('success')}
                          >
                            <StatusIcon status="success" />
                          </TableCell>
                        )}
                        <TableCell
                          classes={{ root: classes.expand }}
                          title={t('transactions.button.more')}
                        >
                          <ExpandIcon
                            expanded={expandedRow.id === paymentId}
                            handleExpandClick={handleExpandClick(paymentId, index)}
                          />
                        </TableCell>
                      </TableRow>
                      {expandedRow.id === paymentId && (
                        <ExpandedTransaction
                          transaction={transaction}
                          colSpan={getMobileColSpan()}
                          isMobile={isMobile}
                          isSmallScreen={isSmallScreen}
                        />
                      )}
                    </React.Fragment>
                  );
                })}

              {!isLoading && isEmpty(transactions) && (
                <TableRow>
                  <TableCell colSpan={getMobileColSpan()} align="center">
                    {t('transactions.error.noTransactions')}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div className={classes.loadSectionHeight}>
          <Loader isLoading={isLoading} />
        </div>
      </div>
    </>
  );
}

export default AllTransactions;
