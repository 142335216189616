import { Theme } from '@material-ui/core';
import { TypographyOptions } from '@material-ui/core/styles/createTypography';

const fontSofiaPro = 'Sofia Pro, sans-serif';

const typography = (theme: Theme): TypographyOptions => ({
  fontFamily: fontSofiaPro,
  h1: {
    fontSize: theme?.typography?.pxToRem(36),
    fontWeight: 500,
  },
  h2: {
    fontSize: theme.typography.pxToRem(32),
    fontWeight: 500,
  },
  h3: {
    fontSize: theme.typography.pxToRem(28),
    fontWeight: 500,
  },
  h4: {
    fontSize: theme.typography.pxToRem(24),
    fontWeight: 500,
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.pxToRem(36),
    },
  },
  h5: {
    fontSize: theme.typography.pxToRem(22),
    fontWeight: 'bold',
  },
  h6: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 'bold',
  },
  body1: {
    fontSize: theme.typography.pxToRem(16),
  },
  caption: {
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 1,
  },
  subtitle2: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1,
    color: '#D8D8D8',
  },
  fontWeightBlack: 900,
  fontWeightBold: theme.typography.fontWeightBold,
});

export default typography;
