import { CategoryName, Permissions } from '@/router/types';
import * as permissions from '@/router/permissions';

export const getRoute = (url: string): string =>
  process.env.REACT_APP_IS_BETA ? `/beta${url}` : url;

export const categoryTypes = {
  publicRoutes: 'PublicRoutes',
  privateRoutesMain: 'PrivateRoutesMain',
  privateRoutesPlain: 'PrivateRoutesPlain',
  notFound: 'NotFound',
} as { [key: string]: CategoryName };

export const internalPaths = {
  home: getRoute('/'),
  newTransactions: getRoute('/new-transactions'),
  allTransactions: getRoute('/all-transactions'),
  exportTransactions: getRoute('/new-transactions/export'),
  totals: getRoute('/totals'),
  merchantAccount: getRoute('/merchant-account'),
  merchantAccountReview: getRoute('/merchant-account/review'),
  merchantAccountEdit: getRoute('/merchant-account/edit'),
  users: getRoute('/users'),
  userInsert: 'add-user',
  userUpsert: getRoute('/users/:userId'),
  settings: getRoute('/settings'),
  downloadQR: getRoute('/download-qr'),
  support: getRoute('/support'),
} as { [key: string]: string };

export const publicPaths = {
  login: getRoute('/login'),
  resendPassword: getRoute('/forget-password'),
  newPassword: getRoute('/newpassword/:token'),
  passwordChanged: getRoute('/password-changed'),
} as { [key: string]: string };

const paths = {
  ...internalPaths,
  ...publicPaths,
  logout: getRoute('/logout'),
  accessDenied: '/403',
  notFound: '*',
} as { [key: string]: string };

export default paths;

export const pathToPermissionMapper = {
  [paths.newTransactions]: {
    oneOf: permissions.NEW_TRANSACTIONS_VIEW,
  },
  [paths.allTransactions]: {
    oneOf: permissions.ALL_TRANSACTIONS_VIEW,
  },
  [paths.exportTransactions]: {
    oneOf: permissions.ALL_TRANSACTIONS_DOWNLOAD,
  },
  [paths.totals]: {
    oneOf: permissions.TOTALS_VIEW,
  },
  [paths.merchantAccount]: {
    oneOf: permissions.MERCHANT_ACCOUNT_VIEW,
  },
  [paths.merchantAccountReview]: {
    oneOf: permissions.MERCHANT_ACCOUNT_REVIEW,
  },
  [paths.merchantAccountEdit]: {
    oneOf: permissions.MERCHANT_ACCOUNT_EDIT,
  },
  [paths.users]: {
    oneOf: permissions.USERS_VIEW,
  },
  [paths.userUpsert]: {
    oneOf: permissions.USERS_MODIFY,
  },
  [paths.settings]: {
    oneOf: permissions.SETTINGS_MODIFY,
  },
  [paths.downloadQR]: {
    oneOf: permissions.QR_CODE_DOWNLOAD,
  },
  [paths.support]: {
    oneOf: permissions.SUPPORT_VIEW,
  },
} as { [key: string]: Permissions };
