import format from 'date-fns/format';
import { DATE_FORMAT } from '@/constants/common';

export const getDisplayDate = (date: unknown): string => {
  try {
    if (typeof date !== 'string' && typeof date !== 'number' && !(date instanceof Date)) return '-';
    return format(new Date(date), DATE_FORMAT);
  } catch {
    return '-';
  }
};

export const getDisplayTime = (date: string): string => {
  try {
    if (typeof date !== 'string') return '-';
    return format(new Date(date), 'HH:mm');
  } catch {
    return '-';
  }
};

export const getDisplayDateTime = (date: string): string => {
  try {
    if (typeof date !== 'string') return '-';
    return `${getDisplayDate(date)} ${getDisplayTime(date)}`;
  } catch {
    return '-';
  }
};

export const getTodayDateTime = (): string => {
  const todayDate = new Date();

  return `${format(todayDate, DATE_FORMAT)}-${format(todayDate, 'HH:mm:ss')}`;
};

export const convertCentsToEuro = (cents: string | number): string => {
  if (!['string', 'number'].includes(typeof cents)) return '';

  if (!parseInt(String(cents), 10)) return '0.00';

  try {
    return (parseInt(String(cents), 10) / 100).toFixed(2);
  } catch {
    return '0.00';
  }
};

export const getLastFourChar = (str: string, prefix = '...x'): string => {
  return typeof str === 'string' ? `${prefix}${str.slice(-4)}` : '-';
};
