import React, { ReactElement } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

type IProps = {
  isLoading: boolean;
  loader?: ReactElement;
  invisible?: boolean;
} & typeof defaultProps;

const defaultProps = {
  loader: <CircularProgress color="secondary" />,
  invisible: true,
};

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
}));

function Overlay(props: IProps): ReactElement {
  const { isLoading, loader, invisible } = props;
  const classes = useStyles();

  return (
    <Backdrop
      invisible={invisible}
      className={classes.backdrop}
      open={isLoading}
      data-testid="overlay"
    >
      {loader}
    </Backdrop>
  );
}

Overlay.defaultProps = defaultProps;

export default Overlay;
