import { ReactElement, ChangeEvent, CSSProperties } from 'react';
import { InputProps as MUIInputProps } from '@material-ui/core/Input';

import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete, {
  AutocompleteCloseReason as MUIAutocompleteCloseReason,
} from '@material-ui/lab/Autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { IPaymentProfile, ChangePaymentProfilesHandler } from '@/types/paymentProfile';
import { UnknownObject } from '@/types/common';
import PaymentProfileAutocompleteOption from './PaymentProfileAutocompleteOption';

type StyleProps = { zIndex: number; elevation: number } & CSSProperties;

const useStyles = makeStyles((theme) => ({
  autocompletePopper: {
    zIndex: ({ zIndex }: StyleProps) => zIndex,
  },
  paymentProfileDropdown: {
    borderRadius: 0,
    margin: 0,
    boxShadow: ({ elevation }: StyleProps) => theme.shadows[elevation],
    border: '1px solid #e0e0e0',
  },
  paymentProfileDropdownOptions: {
    maxHeight: '35vh',

    '& > li': {
      borderBottom: '1px solid #e0e0e0',
      flexDirection: 'column',
      alignItems: 'flex-start',
      minHeight: 0,
      padding: 0,

      '&:hover, &:active, &:focus, &[data-focus="true"]': {
        backgroundColor: '#f4ede4',
      },
    },
    '& > li > div > p': {
      lineHeight: '20px',
    },
    '& > li:last-child': {
      border: 'none',
    },
  },
  input: {
    borderRadius: 0,
    border: 'none',
    backgroundColor: '#fff',
    height: 45,
    boxShadow: ({ elevation }: StyleProps) => theme.shadows[elevation],
    paddingLeft: 12,

    '&.MuiInput-underline:before, &.MuiInput-underline:after, &.MuiInput-underline:hover:not(.Mui-disabled):before':
      {
        border: 'none',
      },
  },
  link: {
    cursor: 'pointer',
  },
}));

interface InputProps extends Partial<MUIInputProps> {
  'data-testid'?: string;
}

type Props = {
  open: boolean;
  onClose: (event: ChangeEvent<UnknownObject>, reason: MUIAutocompleteCloseReason) => void;
  onChange: ChangePaymentProfilesHandler;
  options: IPaymentProfile[];
  elevation?: number;
  zIndex?: number;
  showAllPaymentProfileOption: boolean;
  autoFocus?: boolean;
} & typeof defaultProps;

const defaultProps = {
  elevation: 5,
  zIndex: 1400,
  autoFocus: false,
};

const extraOptions = [
  {
    paymentProfileId: 'emptyOption',
    shopName: 'emptyOption',
    productDescription: 'emptyOption',
    productName: 'emptyOption',
  },
  {
    paymentProfileId: 'all',
    shopName: 'all',
    productDescription: 'all',
    productName: 'all',
  },
];

function PaymentProfileAutocomplete({
  open,
  onClose,
  options,
  onChange,
  elevation,
  zIndex,
  autoFocus,
  showAllPaymentProfileOption,
}: Props): ReactElement {
  const classes = useStyles({ elevation, zIndex });
  const { t } = useTranslation();

  const dropdownOptions = showAllPaymentProfileOption ? [...options, ...extraOptions] : options;

  const handleClose = (event: ChangeEvent<UnknownObject>, reason: MUIAutocompleteCloseReason) => {
    if (reason === 'toggleInput') {
      return;
    }

    onClose(event, reason);
  };

  const handleChange = (event: ChangeEvent<UnknownObject>, value: IPaymentProfile | null) => {
    if (value && (value.paymentProfileId === 'emptyOption' || !value.paymentProfileId)) {
      onChange(null);
    } else {
      onChange(value);
    }
  };

  return (
    <Autocomplete
      open={open}
      classes={{
        popper: classes.autocompletePopper,
        paper: classes.paymentProfileDropdown,
        listbox: classes.paymentProfileDropdownOptions,
        inputRoot: classes.input,
      }}
      disableCloseOnSelect={false}
      onClose={handleClose}
      forcePopupIcon={false}
      onChange={handleChange}
      options={dropdownOptions}
      getOptionLabel={({ paymentProfileId, shopName, productDescription }) =>
        `${paymentProfileId}#${shopName}#${productDescription}`
      }
      renderOption={(option) => <PaymentProfileAutocompleteOption option={option} />}
      renderInput={(params) => (
        <TextField
          {...params}
          margin="none"
          size="small"
          autoFocus={autoFocus}
          placeholder={t('pp.firstLogin.search')}
          onInput={(e) => {
            const target = e.target as HTMLInputElement;
            target.value = target.value.slice(0, 100);
            e.target = target;
          }}
          InputProps={
            {
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              'data-testid': 'ppSearchInput',
            } as InputProps
          }
        />
      )}
    />
  );
}

PaymentProfileAutocomplete.defaultProps = defaultProps;

export default PaymentProfileAutocomplete;
