import React, { ReactElement } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popper, { PopperProps as MUIPopperProps } from '@material-ui/core/Popper';

import PaymentProfileAutocomplete from '@/components/PaymentProfiles/PaymentProfileAutocomplete';
import { IPaymentProfile, ChangePaymentProfilesHandler } from '@/types/paymentProfile';

const useStyles = makeStyles((theme) => ({
  selectionPopup: {
    backgroundColor: '#fff',
    width: 310,
    margin: 0,
    [theme.breakpoints.down('sm')]: {
      width: 330,
    },
    [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
      width: 600,
    },
    marginTop: theme.spacing(1),
  },
}));

type Props = {
  open: boolean;
  onClose: () => void;
  onChange: ChangePaymentProfilesHandler;
  options: IPaymentProfile[];
  settings: {
    anchorEl: MUIPopperProps['anchorEl'];
    showAllPaymentProfileOption: boolean;
    autoFocus: boolean;
  };
};

function PaymentProfileDropdown({
  open,
  onClose,
  onChange,
  options,
  settings,
}: Props): ReactElement {
  const classes = useStyles();
  const { anchorEl, showAllPaymentProfileOption, autoFocus } = settings;

  const handleClose = () => {
    onClose();
  };

  const handleChange = (value: IPaymentProfile | null) => {
    onChange(value);
  };

  return (
    <div data-testid="dropdown">
      {options && Array.isArray(options) && options.length > 0 && (
        <Popper
          open={open}
          anchorEl={anchorEl}
          placement="bottom-start"
          className={classes.selectionPopup}
        >
          <PaymentProfileAutocomplete
            open={open}
            onClose={handleClose}
            onChange={handleChange}
            options={options}
            showAllPaymentProfileOption={showAllPaymentProfileOption}
            zIndex={1000}
            autoFocus={autoFocus}
          />
        </Popper>
      )}
    </div>
  );
}

export default PaymentProfileDropdown;
