import Browser from '@payconiq/browser-detection';
import SUPPORTED_BROWSERS from '@/constants/supportedBrowsers';

function browserCheck(): void {
  Browser.init({
    ua: window.navigator.userAgent,
    validList: SUPPORTED_BROWSERS,
    strict: true,
  });
}

export default browserCheck;
