import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import detector from 'i18next-browser-languagedetector';
import backend from 'i18next-xhr-backend';
import { getRoute } from '@/router/paths';
import BROWSER_STORAGE_KEYS from '@/constants/browserStorageKeys';
import getLanguage from '@/utils/getLanguage';

i18n
  .use(detector)
  .use(backend)
  .use(initReactI18next)
  .init(
    {
      detection: {
        lookupLocalStorage: BROWSER_STORAGE_KEYS.LANGUAGE,
        order: ['localStorage', 'htmlTag'],
      },
      backend: {
        loadPath: getRoute('/locales/{{lng}}/translation.json'),
      },
      fallbackLng: 'en',
      interpolation: {
        escapeValue: true, // react already safes from xss
      },
      react: {
        wait: true,
      },
    },
    () => {
      document.documentElement.lang = getLanguage();
    },
  );

export default i18n;
