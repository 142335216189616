import React, { ReactElement } from 'react';
import Box from '@material-ui/core/Box';

type IProps = {
  children: ReactElement;
};

function LayoutPlain({ children }: IProps): ReactElement {
  return (
    <Box
      sx={{
        p: 5,
        m: 2,
        border: '1px dashed grey',
        textAlign: 'center',
      }}
    >
      {children}
    </Box>
  );
}

export default LayoutPlain;
