import ReactGA from 'react-ga';
import { Location } from 'history';

const IS_PROD_ENV = window.location.host === 'portal.payconiq.com';

export const initGAGenerator = (isProd: boolean): CallableFunction => {
  if (isProd) {
    return () => {
      ReactGA.initialize('UA-58278427-3');
    };
  }

  return () => {};
};

export const initGA = initGAGenerator(IS_PROD_ENV);

export const trackEventGenerator = (isProd: boolean): CallableFunction => {
  if (isProd) {
    return (category: string, action: string, label: string, value: number) => {
      const event = {
        category,
        action,
        label,
        value,
      };
      ReactGA.event(event);
    };
  }

  return () => {};
};

export const trackEvent = trackEventGenerator(IS_PROD_ENV);

export const trackHistoryGenerator = (isProd: boolean): CallableFunction => {
  if (isProd) {
    return (location: Location) => {
      ReactGA.set({ page: location.pathname });
      ReactGA.pageview(location.pathname);
    };
  }
  return () => {};
};

export const trackHistory = trackHistoryGenerator(IS_PROD_ENV);
