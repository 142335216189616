import { AxiosRequestConfig } from 'axios';
import API from '@/constants/api';
import i18n from '@/i18n';
import {
  CLIENT_TIMEOUT,
  CLIENT_ERROR,
  UNAUTHORIZED,
  SESSION_EXPIRED,
  SERVER_ERROR,
} from '@/constants/errorCodes';
import isEmpty from '@/lib/isEmpty';
import { ApiError, ErrorData } from '@/types/api';

type GetApiError = (
  apiError: ApiError,
  apiOptions?: AxiosRequestConfig,
  errorFallbackMessage?: string,
) => {
  status?: number;
  code: string | number;
  message?: string | string[];
};

const isIgnoreUnauthorizedError = (url?: string) => {
  if (!url) {
    return false;
  }

  const ignoreList = [
    API.LOGIN_USER.url,
    API.RESET_PASSWORD.url,
    API.CHECK_TOKEN_FROM_EMAIL.url,
    API.SAVE_NEW_PASSWORD.url,
  ];

  return ignoreList.includes(url);
};

const getApiError: GetApiError = ({ code, response }, apiOptions, errorFallbackMessage) => {
  const defaultErrorMessage = errorFallbackMessage || i18n.t('error.default');

  // Client timeout
  if (code === 'ECONNABORTED') return { code: CLIENT_TIMEOUT, message: defaultErrorMessage };

  // Client error
  if (isEmpty(response)) return { code: CLIENT_ERROR, message: defaultErrorMessage };

  const { data, status } = response;
  if (status === UNAUTHORIZED && !isIgnoreUnauthorizedError(apiOptions?.url)) {
    return {
      status,
      code: SESSION_EXPIRED,
      message: i18n.t('error.sessionExpired'),
    };
  }

  try {
    const parsedError = JSON.parse(data.error) as ErrorData;
    const { code: errorCode, message: errorMessage } = parsedError;
    return {
      status,
      code: errorCode,
      message: data.message || errorMessage || defaultErrorMessage,
    };
  } catch {
    const {
      code: errorCode,
      message: errorMessage,
      messages: errorMessages,
      /* Note: The API response contains a "status" property, which includes server-side error messages, such as API not found.
         Currently, a default message, "Something went wrong. Please try again later," is displayed for all of them.
         To accommodate future requirements of showing specific messages based on the "status" property,
         we should extract the "status" property and incorporate it into the conditional logic before the defaultErrorMessage. */
    } = data;
    return {
      status,
      code: errorCode || SERVER_ERROR,
      message: errorMessage || errorMessages || defaultErrorMessage,
    };
  }
};

export default getApiError;
