import { useState, useCallback, ReactElement } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PaymentProfileProvider from '@/components/PaymentProfiles/PaymentProfileProvider';
import TermsAndConditions from '@/components/TermsAndConditions';
import Sidebar from '@/components/Layouts/Sidebar';
import Header from '@/components/Layouts/Header';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    ...theme.mixins.container,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    flexGrow: 1,
    [theme.breakpoints.up('md')]: {
      maxWidth: '100%',
    },
  },
}));

type IProps = {
  children: ReactElement;
  elevation?: number | null;
} & typeof defaultProps;

const defaultProps = {
  elevation: undefined,
};

function Layout({ children, elevation }: IProps): ReactElement {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = useCallback(() => {
    setMobileOpen((prevState) => !prevState);
  }, []);

  const closeDrawer = useCallback(() => {
    setMobileOpen(false);
  }, []);

  return (
    <PaymentProfileProvider>
      <TermsAndConditions>
        <>
          <Header handleDrawerToggle={handleDrawerToggle} elevation={elevation} />
          <div className={classes.root}>
            <Sidebar
              mobileOpen={mobileOpen}
              handleDrawerToggle={handleDrawerToggle}
              closeDrawer={closeDrawer}
            />
            <main className={classes.content}>{children}</main>
          </div>
        </>
      </TermsAndConditions>
    </PaymentProfileProvider>
  );
}

Layout.defaultProps = defaultProps;

export default Layout;
