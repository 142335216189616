import { useEffect, useRef } from 'react';
import useTransactionPolling from '@/hooks/useTransactionPolling';
import { SESSION_EXPIRED_EVENT } from '@/constants/customEventNames';
import useCustomEvent from '@/hooks/useCustomEvent';

function TransactionPolling() {
  const { startPolling, stopPolling } = useTransactionPolling();
  const isSessionTimedOut = useRef(false);

  useCustomEvent(SESSION_EXPIRED_EVENT, () => {
    isSessionTimedOut.current = true;
    stopPolling();
  });

  useEffect(() => {
    if (!isSessionTimedOut.current) {
      startPolling();
    }

    return stopPolling;
  }, [startPolling, stopPolling]);

  return null;
}

export default TransactionPolling;
