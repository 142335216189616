import React from 'react';
import PropTypes from 'prop-types';
import { withSnackbar } from 'notistack';
import { withTranslation } from 'react-i18next';
import IconButton from '@material-ui/core/IconButton';
import Clear from '@material-ui/icons/Clear';
import ErrorFallback from '@/components/ErrorBoundary/ErrorFallback';
import ErrorList from '@/components/ErrorBoundary/ErrorList';
import eventEmitter from '@/lib/eventEmitter';
import { browserStorageSet, browserStorageGet } from '@/utils/browserStorage';
import { removeToken } from '@/utils/authService';
import { SESSION_EXPIRED_EVENT } from '@/constants/customEventNames';
import { SESSION_EXPIRED } from '@/constants/errorCodes';
import { RESTART_TIMEOUT } from '@/constants/common';
import BROWSER_STORAGE_KEYS from '@/constants/browserStorageKeys';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      id: null,
    };
  }

  static getDerivedStateFromError(error) {
    return { error };
  }

  componentDidMount() {
    window.addEventListener('unhandledrejection', this.handleErrors);
  }

  // For React Errors
  componentDidCatch(error, errorInfo) {
    const { onError } = this.props;
    const id = +new Date();
    this.setState({ error, id });
    onError(error, errorInfo);
    // You can add sentry here
  }

  componentWillUnmount() {
    window.removeEventListener('unhandledrejection', this.handleErrors);
  }

  action = (key) => {
    const { closeSnackbar } = this.props;
    return (
      <IconButton color="inherit" onClick={() => closeSnackbar(key)}>
        <Clear fontSize="small" />
      </IconButton>
    );
  };

  // For Network Errors
  handleErrors = ({ reason: error = '' }) => {
    const { t, enqueueSnackbar } = this.props;
    const id = +new Date();
    this.setState({
      error,
      id,
    });

    if (error.code === SESSION_EXPIRED) {
      removeToken();
      eventEmitter.emit(SESSION_EXPIRED_EVENT);
      return;
    }

    if (error.status?.toString().startsWith('5')) {
      // eslint-disable-next-line
      console.error(error);
      enqueueSnackbar(t('error.server'), {
        variant: 'serverError',
        preventDuplicate: true,
        action: this.action,
      });
      return;
    }

    let errorMessage = '';
    if (!Array.isArray(error.message)) {
      errorMessage = error.message || error || t('error.default');
    } else {
      errorMessage = <ErrorList errors={error.message} />;
    }

    enqueueSnackbar(errorMessage, {
      variant: 'error',
      preventDuplicate: true,
    });
  };

  handleReset = () => {
    localStorage.clear();
    this.setState({ error: null });
    window.location.reload();
  };

  render() {
    const { error, id } = this.state;
    const { children, t, enqueueSnackbar, closeSnackbar, onError, ...rest } = this.props;

    if (error !== null && error?.code !== SESSION_EXPIRED) {
      const lastCrashTime = browserStorageGet(BROWSER_STORAGE_KEYS.LAST_CRASH) || 0;
      const now = +new Date();
      const shouldRestartApp = now - lastCrashTime <= RESTART_TIMEOUT;
      browserStorageSet(BROWSER_STORAGE_KEYS.LAST_CRASH, +new Date());
      if (shouldRestartApp) {
        return (
          <ErrorFallback
            errorTitle={error.code}
            errorDescription={error.message}
            onFallbackButtonClick={this.handleReset}
            id={id}
            {...rest}
          />
        );
      }
    }

    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  t: PropTypes.func.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  closeSnackbar: PropTypes.func.isRequired,
  onError: PropTypes.func,
};

ErrorBoundary.defaultProps = {
  onError: () => {},
};

export default withTranslation()(withSnackbar(ErrorBoundary));
