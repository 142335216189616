import { ReactElement } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import { getRoute } from '@/router/paths';

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.mixins.containerLanding,
  },
  toolbar: {
    color: theme.palette.common.white,
    marginInline: 'auto',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      maxWidth: 1280 - theme.spacing(8.5) * 2, // remove left and right padding;
    },
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'center',
    },
  },
  logo: {
    display: 'block',
  },
  toolbarGutters: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(8.5),
      paddingRight: theme.spacing(8.5),
    },
  },
}));

type IProps = {
  children: ReactElement;
};

function LayoutLanding({ children }: IProps): ReactElement {
  const classes = useStyles();
  const logo = getRoute('/images/logo-white/logo');

  return (
    <>
      <AppBar position="static" color="secondary">
        <Toolbar className={classes.toolbar} classes={{ gutters: classes.toolbarGutters }}>
          <img
            className={classes.logo}
            width="150"
            height="24"
            srcSet={`
              ${logo}.png 1x,
              ${logo}@2x.png 2x
            `}
            src={`${logo}.png`}
            alt="Payconiq logo"
          />
        </Toolbar>
      </AppBar>
      <div className={classes.root}>{children}</div>
    </>
  );
}

export default LayoutLanding;
