import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

function ErrorFallback({
  errorTitle,
  errorDescription,
  buttonText,
  onFallbackButtonClick,
  id,
  ...rest
}) {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        p: 2,
        m: 2,
        border: '1px dashed grey',
        textAlign: 'center',
      }}
      {...rest}
    >
      <Typography variant="h1">{errorTitle || t('error.title')}</Typography>
      <Typography variant="subtitle1" gutterBottom component="div">
        {errorDescription || t('error.default')}
      </Typography>
      <Button variant="contained" color="primary" type="button" onClick={onFallbackButtonClick}>
        {buttonText || t('button.tryAgain')}
      </Button>
      <Typography variant="body2" gutterBottom>
        ID: {id}
      </Typography>
    </Box>
  );
}

ErrorFallback.propTypes = {
  errorTitle: PropTypes.string,
  errorDescription: PropTypes.string,
  id: PropTypes.number.isRequired,
  buttonText: PropTypes.string,
  onFallbackButtonClick: PropTypes.func,
};

ErrorFallback.defaultProps = {
  errorTitle: null,
  errorDescription: null,
  buttonText: null,
  onFallbackButtonClick: () => {},
};

export default ErrorFallback;
