import React, { memo } from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import history from '@/lib/history';
import categories from '@/router/route-configs';
import { categoryTypes } from '@/router/paths';
import ProtectedRoute, { PrivateRoute } from '@/router/ProtectedRoute';
import RouterFallback from '@/router/RouterFallback';
import { IRoute } from '@/router/types';
import Auth from '@/components/Auth';
import SessionExpiry from '@/components/SessionExpiry';

const RouterProvider = memo(() => (
  <Auth>
    <Router history={history}>
      <SessionExpiry />
      <Switch>
        {categories.map((category) => {
          const categoryPathList = category.routes.reduce(
            (acc: string[], crr: IRoute) => [...acc, crr.path],
            [],
          );
          const RouteComponent = [
            categoryTypes.privateRoutesMain,
            categoryTypes.privateRoutesPlain,
          ].includes(category.name)
            ? PrivateRoute
            : Route;
          return (
            <RouteComponent exact path={categoryPathList} key={category.name}>
              <category.layoutComponent>
                <RouterFallback>
                  <>
                    {category.routes.map(({ path, exact, ...rest }) => (
                      <ProtectedRoute key={path} path={path} exact={exact ?? true} {...rest} />
                    ))}
                  </>
                </RouterFallback>
              </category.layoutComponent>
            </RouteComponent>
          );
        })}
      </Switch>
    </Router>
  </Auth>
));

export default RouterProvider;
