import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import { useSnackbar } from 'notistack';
import checkVersion from '@/utils/checkVersion';

function VersionCheck() {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const handleRefresh = () => {
    window.location.reload(true);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      checkVersion().then((data) => {
        if (data) {
          enqueueSnackbar(t('message.updateMerchantPortal'), {
            preventDuplicate: true,
            persist: true,
            action: (
              <Button color="primary" onClick={handleRefresh}>
                {t('button.updateMerchantPortal').toUpperCase()}
              </Button>
            ),
          });
        }
      });
    }, 60 * 60 * 1000);

    return () => clearInterval(interval);
  });

  return null;
}

export default VersionCheck;
