/* eslint-disable */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import '@/i18n';
import browserCheck from '@/lib/browserCheck';
import App from '@/App';
import { initGA } from '@/utils/analytics';

initGA();
browserCheck();
App();
