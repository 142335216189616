import { useEffect, useState, useCallback, useMemo, ReactNode, ReactElement } from 'react';
import PropTypes from 'prop-types';
import API from '@/constants/api';
import request from '@/lib/request';
import authContext, { initStateAuth } from '@/contexts/authContext';
import BROWSER_STORAGE_KEYS from '@/constants/browserStorageKeys';
import { browserStorageSet, browserStorageRemove } from '@/utils/browserStorage';
import { hasToken, setToken, removeToken } from '@/utils/authService';
import { IAuth, LoginPayload, Me } from '@/types/auth';

type IProps = {
  children: ReactNode;
};

function Auth({ children }: IProps): ReactElement {
  const [fetching, setFetching] = useState(initStateAuth.fetching);
  const [isAuthenticated, setAuthenticated] = useState(hasToken());
  const [user, setUser] = useState(initStateAuth.user);

  const resetData = useCallback(() => {
    removeToken();
    setUser(initStateAuth.user);
    setAuthenticated(false);
  }, []);

  const getUser = useCallback(() => {
    setFetching(true);
    return request<Me>(API.GET_CURRENT_USER)
      .then((res) => {
        setAuthenticated(true);
        setUser(res.data);
        return res.data;
      })
      .catch((error) => {
        resetData();
        return Promise.reject(error);
      })
      .finally(() => {
        setFetching(false);
      });
  }, [resetData]);

  const signIn = useCallback((data: LoginPayload, saveUserName: boolean) => {
    setFetching(true);
    return request<IAuth>({
      ...API.LOGIN_USER,
      data,
    })
      .then((res) => {
        if (saveUserName) {
          browserStorageSet(BROWSER_STORAGE_KEYS.USER_NAME, data.email);
        } else {
          browserStorageRemove(BROWSER_STORAGE_KEYS.USER_NAME);
        }
        setToken(res.data);
        setAuthenticated(true);
      })
      .finally(() => {
        setFetching(false);
      });
  }, []);

  const signOut = useCallback(() => {
    request({
      ...API.LOGOUT_USER,
    })
      .then(() => {
        resetData();
      })
      .finally(() => {
        setFetching(false);
      });
  }, [resetData]);

  const contextValue = useMemo(
    () => ({
      fetching,
      isAuthenticated,
      user,
      setToken,
      getUser,
      signIn,
      signOut,
      resetData,
    }),
    [getUser, signIn, signOut, resetData, fetching, isAuthenticated, user],
  );

  useEffect(() => {
    if (hasToken()) {
      getUser();
    }
  }, [getUser]);

  return <authContext.Provider value={contextValue}>{children}</authContext.Provider>;
}

Auth.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Auth;
