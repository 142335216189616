const BROWSER_STORAGE_KEYS: { [key: string]: string } = {
  USER_NAME: 'payconiqApp.userName',
  USER_AUTH: 'payconiqApp.authData',
  LANGUAGE: 'payconiqApp.lang',
  NOTIFICATION_PREFERENCE: 'payconiqApp.notification',
  PAYMENT_PROFILE_TODAYS_TRANSACTIONS: 'payconiqApp.pp_tt',
  PAYMENT_PROFILE: 'payconiqApp.pp',
  LAST_CRASH: 'payconiqApp.lastCrashOccurredAt',
  IS_LOGGED_IN: 'payconiqApp.loggedIn',
  ANNOUNCEMENT: 'payconiqApp.announcement',
  TC: 'payconiqApp.confirmedTC',
  SHOULD_CLEAR_USER: 'payconiqApp.clearUser',
  LAST_PATH: 'payconiqApp.lastPath',
};

export default BROWSER_STORAGE_KEYS;
