import React from 'react';
import TransactionProvider from '@/components/Transactions/TransactionProvider';
import AllTransactions from '@/components/AllTransactions';
import PaymentProfileSelection from '@/components/PaymentProfiles/PaymentProfileSelection';
import StickerBanner from '@/components/Banners/StickerBanner';
import PreDefinedStickerBanner from '@/components/Banners/PreDefinedStickerBanner/PreDefinedStickerBanner';

function AllTransactionsPage() {
  return (
    <>
      <PaymentProfileSelection />
      <PreDefinedStickerBanner />
      <TransactionProvider>
        <StickerBanner />
        <AllTransactions />
      </TransactionProvider>
    </>
  );
}

export default AllTransactionsPage;
