import React, { ReactElement, useEffect } from 'react';
import { useHistory, matchPath } from 'react-router-dom';
import { isAccessible } from '@/router/router-middleware';
import paths, { internalPaths, pathToPermissionMapper } from '@/router/paths';
import { browserStorageRemove, browserStorageGet } from '@/utils/browserStorage';
import BROWSER_STORAGE_KEYS from '@/constants/browserStorageKeys';

function HomePage(): ReactElement {
  const history = useHistory();

  useEffect(() => {
    const lastPath = browserStorageGet(BROWSER_STORAGE_KEYS.LAST_PATH) as string;
    const matchedLastPath = matchPath(lastPath, {
      path: Object.values(internalPaths),
      exact: true,
      strict: true,
    });
    const lastPathPermissions = matchedLastPath?.path
      ? pathToPermissionMapper[matchedLastPath?.path]
      : null;
    const isLastPathAccessible = lastPathPermissions
      ? isAccessible(lastPathPermissions) === 'pass'
      : false;

    if (isLastPathAccessible) {
      browserStorageRemove(BROWSER_STORAGE_KEYS.LAST_PATH);
      history.replace({ pathname: lastPath });
    } else if (isAccessible(pathToPermissionMapper[paths.newTransactions]) === 'pass') {
      history.replace({ pathname: paths.newTransactions });
    } else {
      history.replace({ pathname: paths.allTransactions });
    }
  }, [history]);

  return <div />;
}

export default HomePage;
