import React from 'react';
import PropTypes from 'prop-types';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import { makeStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';

const statusIcons = {
  success: CheckCircleOutline,
};

const useStyles = makeStyles({
  success: {
    color: green[600],
  },
});

function StatusIcon({ status }) {
  const classes = useStyles();
  const Icon = statusIcons[status];
  const iconClass = classes[status];

  return Icon ? <Icon style={{ verticalAlign: 'middle' }} className={iconClass || ''} /> : '-';
}

StatusIcon.propTypes = {
  status: PropTypes.string.isRequired,
};

export default StatusIcon;
