import axios from 'axios';
import { getRoute } from '@/router/paths';
import getApiError from '@/utils/getApiError';
import { ApiError } from '@/types/api';

type ApiResponse = {
  data: {
    version: string;
  };
};

async function checkVersion(): Promise<boolean> {
  try {
    const response: ApiResponse = await axios.get(getRoute('/current-version.json'));
    const localVersion = (document.head.querySelector('[name=version]') as HTMLMetaElement).content;
    return response.data.version !== localVersion;
  } catch (error) {
    return Promise.reject(getApiError(error as ApiError));
  }
}

export default checkVersion;
