import { ReactElement, useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import usePaymentProfiles from '@/hooks/usePaymentProfiles';
import useTC from '@/hooks/useTC';
import paths from '@/router/paths';
import PaymentProfileModal from '@/components/PaymentProfiles/PaymentProfileModal';
import PaymentProfileDropdown from '@/components/PaymentProfiles/PaymentProfileDropdown';
import { IPaymentProfile } from '@/types/paymentProfile';

const useStyles = makeStyles((theme) => ({
  selectedProfile: {
    marginBottom: theme.spacing(2),
    minHeight: 50,
  },
  selectedShopName: {
    display: 'flex',
    alignItems: 'center',
    '& p': {
      marginRight: theme.spacing(3),
    },
  },
  shopName: {
    display: 'inline-block',
    verticalAlign: 'middle',
    maxWidth: 120,
    [theme.breakpoints.up(500)]: {
      maxWidth: 150,
    },
    ...theme.mixins.noWrap,
  },
  profileId: {
    display: 'flex',
    '& strong': {
      display: 'inline-block',
      width: '45px',
      paddingLeft: theme.spacing(0.5),
      direction: 'rtl',
      textAlign: 'left',
      ...theme.mixins.noWrap,
    },
  },
}));

function PaymentProfileSelection(): ReactElement {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(767));
  const isTabletAndMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { showTCModal } = useTC() || {};
  const {
    paymentProfiles,
    handleChangePaymentProfiles,
    selectedPaymentProfile,
    selectedPaymentProfileTodayTransactions,
  } = usePaymentProfiles() || {};
  const switcherRef = useRef(null);
  const [modalOpen, setModalOpen] = useState(false);

  const location = useLocation();
  const isTodaysTransactionsPage = location.pathname === paths.newTransactions;
  const isAllTransactionsPage = location.pathname === paths.allTransactions;

  const [modalSettings, setModalSettings] = useState({
    canClose: false,
    showAllPaymentProfileOption: !isTodaysTransactionsPage,
  });
  const [dropdownSettings, setDropdownSettings] = useState({
    anchorEl: null,
    showAllPaymentProfileOption: !isTodaysTransactionsPage,
  });
  const dropdownOpen = Boolean(dropdownSettings.anchorEl);

  const handleClose = () => {
    if (dropdownOpen) {
      setDropdownSettings({
        ...dropdownSettings,
        anchorEl: null,
      });
    }

    if (modalOpen) {
      setModalOpen(false);
    }
  };

  const handleOpen = () => {
    if (dropdownOpen || modalOpen) {
      return handleClose();
    }

    if (isMobile) {
      setModalOpen(true);
      setModalSettings({ ...modalSettings, canClose: true });
      return null;
    }

    setDropdownSettings({
      ...dropdownSettings,
      anchorEl: switcherRef.current,
    });
    return null;
  };

  const handleChange = (value: IPaymentProfile | null) => {
    if (handleChangePaymentProfiles) handleChangePaymentProfiles(value);
  };

  useEffect(() => {
    setModalOpen(
      (!showTCModal && isTodaysTransactionsPage && !selectedPaymentProfileTodayTransactions) ||
        (isAllTransactionsPage && !selectedPaymentProfile),
    );
  }, [
    showTCModal,
    isTodaysTransactionsPage,
    selectedPaymentProfileTodayTransactions,
    isAllTransactionsPage,
    selectedPaymentProfile,
  ]);

  const renderDropdown = () => {
    return (
      <PaymentProfileDropdown
        open={dropdownOpen}
        onClose={handleClose}
        onChange={handleChange}
        options={paymentProfiles || []}
        settings={{ ...dropdownSettings, autoFocus: !isTabletAndMobile }}
      />
    );
  };

  const renderModal = () => {
    return (
      <PaymentProfileModal
        open={modalOpen}
        onClose={handleClose}
        onChange={handleChange}
        options={paymentProfiles || []}
        settings={{ ...modalSettings, autoFocus: !isTabletAndMobile }}
      />
    );
  };

  const paymentProfile = isTodaysTransactionsPage
    ? selectedPaymentProfileTodayTransactions
    : selectedPaymentProfile;

  const paymentProfileDropdownDefaultLabel = isTodaysTransactionsPage
    ? 'pp.firstLogin.title'
    : 'pp.allPaymentProfiles';

  return (
    <Box data-testid="payment-profile-selection">
      {paymentProfiles && Array.isArray(paymentProfiles) && paymentProfiles.length > 0 && (
        <div className={classes.selectedProfile} ref={switcherRef}>
          <div className={classes.selectedShopName}>
            <Typography>
              {!paymentProfile || paymentProfile.paymentProfileId === 'all' ? (
                t(paymentProfileDropdownDefaultLabel)
              ) : (
                <>
                  <strong className={classes.shopName} title={paymentProfile.shopName}>
                    {paymentProfile.shopName}
                  </strong>
                  &nbsp;|&nbsp;
                  {paymentProfile.productDescription}
                </>
              )}
            </Typography>
            {paymentProfiles.length > 1 && (
              <div onClick={handleOpen} data-testid="open">
                <IconButton size="small" color="primary">
                  {dropdownOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </div>
            )}
          </div>
          <Typography variant="caption" className={classes.profileId}>
            {paymentProfile && paymentProfile.paymentProfileId !== 'all' && (
              <Trans
                i18nKey="pp.profileId"
                values={{ profileId: paymentProfile.paymentProfileId }}
                components={{ bold: <strong /> }}
              />
            )}
          </Typography>
        </div>
      )}
      {isMobile ? (
        <>{renderModal()}</>
      ) : (
        <>
          {renderDropdown()}
          {(isTodaysTransactionsPage || isAllTransactionsPage) && renderModal()}
        </>
      )}
    </Box>
  );
}

export default PaymentProfileSelection;
