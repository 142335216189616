import { lazy } from 'react';
import paths, { categoryTypes, pathToPermissionMapper } from '@/router/paths';
import { LazyComponentType, ICategory } from '@/router/types';
import { /* needAuth, needUnAuth, */ checkPermissions } from '@/router/router-middleware';

// Layouts
import Layout, { LayoutLanding, LayoutPlain } from '@/components/Layouts';

// Default Pages
import HomePage from '@/pages/HomePage';
import TodaysTransactionsPage from '@/pages/TodaysTransactionsPage';
import AllTransactionsPage from '@/pages/AllTransactionsPage';

// Lazy-Loaded Pages
const LoginPage: LazyComponentType = lazy(() => import('@/pages/LoginPage'));
const LogoutPage: LazyComponentType = lazy(() => import('@/pages/LogoutPage'));
const NewPasswordPage: LazyComponentType = lazy(() => import('@/pages/NewPasswordPage'));
const ResendPasswordPage: LazyComponentType = lazy(() => import('@/pages/ResendPasswordPage'));
const PasswordChangeSuccessfulPage: LazyComponentType = lazy(
  () => import('@/pages/PasswordChangeSuccessfulPage'),
);
const ExportTransactionsPage: LazyComponentType = lazy(
  () => import('@/pages/ExportTransactionsPage'),
);
const TotalsPage: LazyComponentType = lazy(() => import('@/pages/TotalsPage'));
const MerchantAccountPage: LazyComponentType = lazy(() => import('@/pages/MerchantAccountPage'));
const MerchantAccountReviewPage: LazyComponentType = lazy(
  () => import('@/pages/MerchantAccountReviewPage'),
);
const MerchantAccountEditPage: LazyComponentType = lazy(
  () => import('@/pages/MerchantAccountEditPage'),
);
const UsersPage: LazyComponentType = lazy(() => import('@/pages/UsersPage'));
const UserUpsertPage: LazyComponentType = lazy(() => import('@/pages/UserUpsertPage'));
const SettingsPage: LazyComponentType = lazy(() => import('@/pages/SettingsPage'));
const DownloadQRPage: LazyComponentType = lazy(() => import('@/pages/DownloadQRPage'));
const SupportPage: LazyComponentType = lazy(() => import('@/pages/SupportPage'));
const AccessDeniedPage: LazyComponentType = lazy(() => import('@/pages/AccessDeniedPage'));
const NotFoundPage: LazyComponentType = lazy(() => import('@/pages/NotFoundPage'));

const categories: ICategory[] = [
  {
    name: categoryTypes.publicRoutes,
    layoutComponent: LayoutLanding,
    routes: [
      {
        path: paths.login,
        component: () => LoginPage,
        exact: false,
      },
      {
        path: paths.resendPassword,
        component: () => ResendPasswordPage,
        exact: false,
      },
      {
        path: paths.newPassword,
        component: () => NewPasswordPage,
        exact: false,
      },
      {
        path: paths.passwordChanged,
        component: () => PasswordChangeSuccessfulPage,
      },
    ],
  },
  {
    name: categoryTypes.privateRoutesMain,
    layoutComponent: Layout,
    routes: [
      {
        path: paths.home,
        component: () => HomePage,
      },
      {
        path: paths.newTransactions,
        component: () => TodaysTransactionsPage,
        middleware: [checkPermissions],
        permissions: pathToPermissionMapper[paths.newTransactions],
      },
      {
        path: paths.allTransactions,
        component: () => AllTransactionsPage,
        middleware: [checkPermissions],
        permissions: pathToPermissionMapper[paths.allTransactions],
      },
      {
        path: paths.exportTransactions,
        component: () => ExportTransactionsPage,
        middleware: [checkPermissions],
        permissions: pathToPermissionMapper[paths.exportTransactions],
      },
      {
        path: paths.totals,
        component: () => TotalsPage,
        middleware: [checkPermissions],
        permissions: pathToPermissionMapper[paths.totals],
      },
      {
        path: paths.merchantAccount,
        component: () => MerchantAccountPage,
        middleware: [checkPermissions],
        permissions: pathToPermissionMapper[paths.merchantAccount],
      },
      {
        path: paths.merchantAccountReview,
        component: () => MerchantAccountReviewPage,
        middleware: [checkPermissions],
        permissions: pathToPermissionMapper[paths.merchantAccountReview],
      },
      {
        path: paths.merchantAccountEdit,
        component: () => MerchantAccountEditPage,
        middleware: [checkPermissions],
        permissions: pathToPermissionMapper[paths.merchantAccountEdit],
      },
      {
        path: paths.users,
        component: () => UsersPage,
        middleware: [checkPermissions],
        permissions: pathToPermissionMapper[paths.users],
      },
      {
        path: paths.userUpsert,
        component: () => UserUpsertPage,
        middleware: [checkPermissions],
        permissions: pathToPermissionMapper[paths.userUpsert],
      },
      {
        path: paths.settings,
        component: () => SettingsPage,
        middleware: [checkPermissions],
        permissions: pathToPermissionMapper[paths.settings],
      },
      {
        path: paths.downloadQR,
        component: () => DownloadQRPage,
        middleware: [checkPermissions],
        permissions: pathToPermissionMapper[paths.downloadQR],
      },
      {
        path: paths.support,
        component: () => SupportPage,
        middleware: [checkPermissions],
        permissions: pathToPermissionMapper[paths.support],
      },
      {
        path: paths.logout,
        component: () => LogoutPage,
      },
    ],
  },
  {
    name: categoryTypes.privateRoutesPlain,
    layoutComponent: LayoutPlain,
    routes: [
      {
        path: paths.accessDenied,
        component: () => AccessDeniedPage,
        exact: false,
      },
    ],
  },
  {
    name: categoryTypes.notFound,
    layoutComponent: LayoutPlain,
    routes: [
      {
        path: paths.notFound,
        component: () => NotFoundPage,
        exact: false,
      },
    ],
  },
];

export default categories;
