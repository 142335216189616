import { getParsedToken, hasToken } from '@/utils/authService';
import paths from '@/router/paths';
import { IParam, MiddlewareResponse, Permissions } from '@/router/types';
import BROWSER_STORAGE_KEYS from '@/constants/browserStorageKeys';
import { browserStorageSet } from '@/utils/browserStorage';

const response = (action: string, path: string) => ({ action, path });

export function needAuth(route: IParam): MiddlewareResponse {
  if (hasToken()) return response('pass', route.path);
  browserStorageSet(BROWSER_STORAGE_KEYS.SHOULD_CLEAR_USER, true);
  return response('redirect', paths.login);
}

export function needUnAuth(route: IParam): MiddlewareResponse {
  if (!hasToken()) return response('pass', route.path);
  return response('redirect', paths.home);
}

export function isAccessible(permissions: Permissions): string {
  const { oneOf, noneOf, allOf } = permissions;
  const userPermissions = getParsedToken()?.permissions || [];

  if (
    (!oneOf || oneOf.some((perm) => userPermissions.includes(perm))) &&
    (!noneOf || !userPermissions.some((perm) => noneOf.includes(perm))) &&
    (!allOf || allOf.every((perm) => userPermissions.includes(perm)))
  ) {
    return 'pass';
  }
  return 'redirect';
}

export function checkPermissions(route: IParam): MiddlewareResponse {
  if (isAccessible(route.permissions || {}) === 'pass') {
    return response('pass', route.path);
  }
  return response('redirect', paths.accessDenied);
}
